import React, { useState, useEffect } from 'react';
import Header from "../components/landingViews/header.jsx"
import Preloader from "../components/landingViews/preloader.jsx";
import BannerStyleFour from "../components/landingViews/banner.jsx";
import ProcessStyleTwo from "../components/landingViews/process.jsx";
import ServicesOverview from "../components/landingViews/service.jsx";
import AboutSection from "../components/landingViews/about.jsx";
import PricingSection from "../components/landingViews/pricing.jsx";
import FAQSection from "../components/landingViews/faq.jsx";
import TeamGallery from "../components/landingViews/team.jsx";
import Footer from "../components/landingViews/footer.jsx";


const LandingPage = () => {
    const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowPreloader(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);


    return (
       <>
      {showPreloader ? (
        <Preloader />
      ) : (
        <div className="bg-fixed dark-layout text-light" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/banner-2.jpg)` }}>
          <Header />
          <BannerStyleFour />
          <ProcessStyleTwo />
          <ServicesOverview />
          <AboutSection />
          <PricingSection />
          <FAQSection />
          <TeamGallery />
          <Footer />
        </div>
      )}
    </>
    )

}

export default LandingPage;