import React,{useState} from 'react';
import Typed from 'react-typed';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HelperModal from '../helpers/modal';
import { ApiService, setAuthorization } from '../../services/ApiService';
import { useNavigate } from 'react-router-dom';
import { SetCookie,SetUserLogin,RedirectToLogin } from '../../components/helpers/helper';
import { useMyContext } from '../../MyContext';

const BannerStyleFour = () => {
  const navigate = useNavigate();
  const { userAuthenticated,setUserAuthenticated } = useMyContext();
  const [openModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalData, setModalData] = useState({});

  const hideModal = () => {
    setModalType(null);
    setShowModal(false);
};
  const showModal = async (modalName = null, data = null) => {
    if (modalName == null) {
      return false;
    }
    setModalData({})
    switch (modalName) {
      case "quick_scan":
      if (data != null) {
         // setViewFile(null);
         // setFileType(null);
          //let fileDetails = getFileDetails(data);
  
          setModalType(modalName);
          setShowModal(true);
        }
      break;
    }
  };

  const quickScan_API = async(modalName = null, data = null)=>{
    //call API here to
    console.log("quickScanAPI-->",data);
    let payloadUrl = "auth/register"
    let method = "POST"
    let formData = {fullname:'username',mobile:data.mobile,password:data.password,account_type:1,otp:1234,register_type:'quickPay'}
    const res = await ApiService.fetchData(payloadUrl,method,formData)
    console.log(res);
    if( res && res.status_code){
        let result = res.results
        if(res.status_code == "dc200" && res.message == "Success"){
          let auth = SetUserLogin(result);
          if(auth){
          setUserAuthenticated(true);
          if(data.scantype == null){
            data.scantype = 11;
          }
          navigate(`/dashboard?planID=${data.scantype}`);
          }
        }else{
          //toggleAlert({ show: true, type: 'danger', message: res.message });
          return false;
        }
    }
  
  }
  return (
    <>
    <div className="banner-style-four-area text-center text-light" style={{ backgroundImage: 'url(assets/img/42.png)' }}>
      <div className="banner-style-four-shape">
        <img src="assets/img/44.png" alt="Image Not Found" />
      </div>
      {/* Single Item */}
      <div className="banner-style-four">
        <div className="container">
          <div className="row align-center">
            <div className="col-xl-8 offset-xl-2">
              <div className="content">
                <h3 className="title">
                Revolutionising Respiratory Healthcare in{' '}
                  <span className="header-caption" id="page-top">
                    {/* type headline start*/}
                    <span className="cd-headline clip is-full-width">
                      {/* ROTATING TEXT */}
                      <Typed
                        strings={['Chest X-ray', 'X-ray Analysis', 'Tuberculosis']}
                        typeSpeed={70}
                        backSpeed={50}
                        loop
                      />
                    </span>
                    {/* type headline end */}
                  </span>
                </h3>
                <p><b>Powered By Department of Pulmonary Medicine, SGPGI, Lucknow</b></p>
                {/* <p>
                  Revolutionize your chest X-ray interpretation with Dectrocel's cutting-edge DecXpert CXR. AI-driven solution is designed to bring accuracy and efficiency to your practice.
                </p> */}
                <p>DecXpert® AI Tool is engineered to make accurate assessments of lung health (TB, COPD and Pneumonia) using digital chest x-ray images. Driven by Advanced Artificial Intelligence and Machine Learning technology, DecXpert® uses digital chest x-ray images to detect and 15 other critical respiratory conditions and provide insights into the wellbeing of the lungs.</p>
                <p> " Check your lung health now!  Simple and fast "</p>
                <button className="btn circle mt-25 btn-md btn-theme secondary animation" onClick={(e) => showModal('quick_scan', '', e)}> Do a Quick Scan </button>
               
              </div>
              <div className="illustration">
                <img src="assets/img/banner-1.jpeg" alt="Image Not Found" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Single Item */}
    </div>
    {(() => {
              if (modalType && modalType != "" && modalType != null) {
                if (modalType == "quick_scan") {
                  return (
                    <HelperModal
                      show={openModal}
                      modalType={modalType}
                      hideModal={hideModal}
                      modalData={{ }}
                      formSubmit={quickScan_API}
                    />
                  );
                }
              }
              
          })()}
    </>
  );
};

export default BannerStyleFour;
