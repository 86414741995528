import React,{useState,useEffect} from 'react';
import { Navbar, Nav, NavDropdown,Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './css/header.css';

const Header = () => {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
//${isSticky ? 'sticked' : ''}
  return (
    <>
    <header >
    <Navbar expand="lg" fixed="top"
      className={`navbar mobile-sidenav navbar-sticky navbar-default validnavs navbar-fixed white fixed-top on no-full ${isSticky ? '' : 'no-background'}`}
           
    >
      <div className="container d-flex justify-content-between align-items-center">
        <div>
        <Navbar.Toggle aria-controls="navbar-menu">
          <i className="fa fa-bars" style={{color:"white"}}></i>
        </Navbar.Toggle>

        <Navbar.Brand href="/">
          <img src={process.env.PUBLIC_URL + '/assets/img/sgpgi_logo22.png'} style={{ height: '60px' }}></img>
          <img src="assets/img/dexpert_white01.png" className="logo mt-8" alt="Logo" style={{ height: '40px' }} /><span style={{position:"absolute"}}>&#174;</span>
        </Navbar.Brand>
        </div>
       <div>
        <Navbar.Collapse id="navbar-menu">
          <Nav className="ml-auto" style={{fontSize:'20px'}}>
            <Nav.Link href="#process" className="smooth-menu">
              Product
            </Nav.Link>
            <Nav.Link href="#services" className="smooth-menu">
              Services
            </Nav.Link>
            <Nav.Link href="#about" className="smooth-menu">
              About
            </Nav.Link>
            <Nav.Link href="#pricing" className="smooth-menu">
              Pricing
            </Nav.Link>
            <Nav.Link href="#blog" className="smooth-menu">
              Team
            </Nav.Link>
            <Nav.Link href="#contact" className="smooth-menu">
              Contact
            </Nav.Link>
            {/* <Nav.Link href="/terms" className="smooth-menu">
              <small>Terms & Conditions</small>
            </Nav.Link>
            <Nav.Link href="/refund" className="smooth-menu">
              <small>Refund Policy</small>
            </Nav.Link> */}
          </Nav>

          
        </Navbar.Collapse>
      </div>
      <div className="attr-nav">
            <ul>
              <li className="button border-btn secondary">
                <Link to="/register" className='mr-10' style={{fontSize:"20px"}}>Register</Link>

                {/* <a  href="contact-us.html"> </a> */}
                
                <Link to="/login">
                <Button variant="primary" className="button btn-sm primary" style={{fontSize:"20px"}}> Log in</Button>
                </Link>
                  
               
              </li>
            </ul>
          </div>
      </div>
    </Navbar>
  </header>
  </>
  );
};

export default Header;
