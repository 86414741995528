import React, { useState, useEffect } from 'react';
import Header_OnlyLogo from "../components/landingViews/header_onlyLogo.jsx";
import Preloader from "../components/landingViews/preloader.jsx";
import BannerStyleFour from "../components/landingViews/banner.jsx";
import ProcessStyleTwo from "../components/landingViews/process.jsx";
import ServicesOverview from "../components/landingViews/service.jsx";
import AboutSection from "../components/landingViews/about.jsx";
import PricingSection from "../components/landingViews/pricing.jsx";
import FAQSection from "../components/landingViews/faq.jsx";
import TeamGallery from "../components/landingViews/team.jsx";
import Footer from "../components/landingViews/footer.jsx";


const RefundPolicy = () => {
    const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowPreloader(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);


    return (
       <>
      {showPreloader ? (
        <Preloader />
      ) : (
        <div className="bg-fixed dark-layout text-light" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/banner-2.jpg)` }}>
           <Header_OnlyLogo />
            <div classname="row">
        <div classname="container">
        <div className='col-lg-12 col-md-12'>
            <div className='col-lg-8 ml-200 pt-150 pb-50'>

        <center><h1>Refund Policy</h1></center>
        <>
  <p
    className="c0 c4"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      height: "11pt",
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      className="c1"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        fontStyle: "normal",
        fontWeight: 400,
        textDecoration: "none",
        verticalAlign: "baseline"
      }}
    />
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>Thank you for choosing</span>
    <span className="c6">
      <strong>&nbsp;DecXpert</strong>
    </span>
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        &nbsp;for your healthcare insights needs. We are committed to ensuring
        your satisfaction with our services. If you are not entirely satisfied
        with your purchase, we are here to help.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>1.&nbsp;</span>
    <i>
      <span className="c3">Subscription Refunds</span>
    </i>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        1.1 Eligibility for Refunds
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        - Refunds are available only for first-time subscribers who are not
        satisfied with our services.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        - To be eligible for a refund, you must request it within 14 days of
        your initial subscription purchase.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        1.2 Non-Refundable Situations
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        - Renewals of subscription plans are non-refundable.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        - Refunds are not available for promotional or discounted plans.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        - Any customization or additional services provided at your request are
        non-refundable.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>2.&nbsp;</span>
    <i>
      <span className="c3">Requesting a Refund</span>
    </i>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        2.1 How to Request a Refund
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        - To initiate a refund, please contact our customer support team at
        [ceo@dectrocel.com] with your subscription details and reason for the
        request.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        - Our support team will review your request and notify you of the
        approval or rejection of your refund.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        2.2 Refund Process
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        - If your refund is approved, we will process it within 7-10 business
        days.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        - The refund will be credited to your original method of payment.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>3.&nbsp;</span>
    <i>
      <span className="c3">Cancellation Policy</span>
    </i>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        3.1 Cancelling Your Subscription
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        - You can cancel your subscription at any time by accessing your account
        settings or contacting our customer support team at [ceo@dectrocel.com].
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>4.&nbsp;</span>
    <i>
      <span className="c3">Compliance and Use of Services</span>
    </i>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        4.1 Permitted Use
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        - You must utilize our services solely for purposes permitted by the
        Terms &amp; Conditions (T&amp;C).
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        - You must comply with all applicable laws, regulations, and generally
        accepted practices or guidelines.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>5.&nbsp;</span>
    <i>
      <span className="c3">Changes to the Return Policy</span>
    </i>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        We reserve the right to update or change our return policy at any time.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>6.&nbsp;</span>
    <i>
      <span className="c3">Contact Us</span>
    </i>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Dectrocel
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Email: [ceo@dectrocel.com]&nbsp;
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Thank you for your understanding and for choosing DecXpert for your
        healthcare insights needs.
      </span>
    </span>
  </p>
  <p
    className="c0"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c1"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        -Last updated [01-09-2024]
      </span>
    </span>
  </p>
  <p>
    <br />
    &nbsp;
  </p>
</>







            </div>
        </div>
        </div>
            </div>
        
        </div>
      )}
    </>
    )

}

export default RefundPolicy;