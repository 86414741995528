import React, { useState,useEffect } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import Sidebar from '../components/partial/sidebar';
import Topbar from '../components/partial/topbar';
import Content from '../components/partial/content';
import NewContent from '../components/partial/content2';
import "../assets/css/sb-admin-2.css";
import PricingComponent from '../components/partial/pricing';
import { useNavigate } from 'react-router-dom';
import { RedirectToLogin } from '../components/helpers/helper';
import { Spinner } from 'react-bootstrap';
import Scanpage from '../components/partial/scanpage';
import Profile from '../components/partial/profile';
import PrintablePage from '../components/partial/reportpage';
import AllReportPage from '../components/partial/allreports';

const Dashboard = () => {
  const navigate = useNavigate();
  const [isSidebarToggled, setSidebarToggled] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const toggleSidebar = () => {
    setSidebarToggled(!isSidebarToggled);
  };

  useEffect(() => {
    const currentUserSession = localStorage.getItem('currentUserSession');
  
    if (!currentUserSession) {
      // 'currentUserSession' not found in local storage, redirect to login
      RedirectToLogin();
    }
    else{
      setLoading(false);
    }
  }, []);

  // Show loader until authentication is complete
  if (isLoading) {
    return <><center><Spinner/></center></>; // Replace with your loader component
  }

  return (
    <>
      <div id="page-top" className={isSidebarToggled ? 'sidebar-toggled' : ''}>
        <div id="wrapper">
          <Sidebar isSidebarToggled={isSidebarToggled} toggleSidebar={toggleSidebar} />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />

              {/* Route Handling for Dashboard */}
              <Routes>
                <Route path="/" element={<Content />} />
                <Route path="new" element={<NewContent />} />
                <Route path="plan" element={<PricingComponent/>}></Route>
                <Route path="scan" element={<Scanpage/>}></Route>
                <Route path="profile" element={<Profile/>}></Route>
                <Route path="report" element={<PrintablePage/>}></Route>
                <Route path="allreports" element={<AllReportPage/>}></Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
