import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { ApiService } from '../../services/ApiService';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';
import './css/login.css';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const toggleAlert = (val) => {
    setShowAlert(val);
  }

  useEffect(() => {
    if(localStorage.getItem('currentUserSession')){
      navigate('/dashboard')
    }
  },[])

  const onSubmit = async (data = {}) => {
    if(data == null){
      return false
    }
    setLoading(true);
    let payloadUrl = "auth/sendOTP"
    let method = "POST"
    let formData = {
      mobilenumber: parseInt(data.mobile, 10),
      type: 'forgotpassword'
    }
    const res = await ApiService.fetchData(payloadUrl,method,formData)
    if(res && res.status_code){
      if(res.status_code == "dc200"){
        setOtpSent(true);
      }
      if(res.status_code !== "dc200"){
        toggleAlert({ show: true, type: 'danger', message: res.message });
      }
    }
    setLoading(false);
  }

  const onChangePassword = async (data) => {
    if(data.newPassword !== data.confirmPassword) {
      toggleAlert({ show: true, type: 'danger', message: 'Passwords do not match' });
      return;
    }

    setLoading(true);
    let payloadUrl = "auth/forgotpassword"
    let method = "POST"
    let formData = {
      mobilenumber: parseInt(data.mobile, 10),
      otp: parseInt(data.otp),
      newpassword: data.newPassword
    }
    
    const res = await ApiService.fetchData(payloadUrl,method,formData)
    if(res && res.status_code == "dc200"){
      toggleAlert({ show: true, type: 'success', message: 'Password changed successfully' });
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } else {
      toggleAlert({ show: true, type: 'danger', message: res.message });
    }
    setLoading(false);
  }

  return (
    <>
    <div className="login-dark" style={{ minHeight: '100vh', background: '#214a80', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize:"16px" }}>
      <Container>
        <form style={{ maxWidth: '400px' }} onSubmit={handleSubmit(!otpSent ? onSubmit : onChangePassword)}>
          <img src="/assets/img/sgpgi_logo22.png" style={{ height: '80px',backgroundColor:"white" }}/>
          <img src="assets/img/dexpert_white01.png" className="logo" alt="Logo" style={{ height: '40px' }} />
          <span style={{position:"absolute",marginTop:"10px"}}>&#174;</span>
          <div className='text-type mt-20'>Forgot Password</div>
          <div className="illustration"><i className="icon ion-ios-locked-outline"></i></div>
          
          {!otpSent ? (
            <Form.Group>
              <Form.Control 
                style={{fontSize:"18px"}} 
                type="number" 
                {...register("mobile", { 
                  required: "Mobile number is required",
                  maxLength: {value: 10, message: "Enter only 10 digit mobile number" },
                  minLength: {value: 10, message: "Enter at least 10 digits for the mobile number"}
                })} 
                placeholder="Mobile Number"
                className="form-control"
              />
              {errors.mobile && <small className="form-text text-danger">{errors.mobile.message}</small>}
            </Form.Group>
          ) : (
            <>
              <Form.Group>
                <Form.Control 
                  style={{fontSize:"18px"}} 
                  type="number" 
                  value={watch('mobile')}
                  className="form-control" 
                  disabled
                />
              </Form.Group>

              <Form.Group>
                <Form.Control 
                  style={{fontSize:"18px"}} 
                  type="text" 
                  {...register('otp', { 
                    required: 'OTP is required',
                    minLength: {value: 4, message: "Enter 4 digit OTP" }
                  })} 
                  placeholder="Enter OTP" 
                  className="form-control" 
                />
                {errors.otp && <small className="form-text text-danger">{errors.otp.message}</small>}
              </Form.Group>

              <Form.Group>
                <Form.Control 
                  style={{fontSize:"18px"}} 
                  type="password" 
                  {...register("newPassword", { 
                    required: "New password is required",
                    minLength: {value: 6, message: "Minimum length should be 6 characters" }
                  })} 
                  placeholder="New Password" 
                  className="form-control" 
                />
                {errors.newPassword && <small className="form-text text-danger">{errors.newPassword.message}</small>}
              </Form.Group>

              <Form.Group>
                <Form.Control 
                  style={{fontSize:"18px"}} 
                  type="password" 
                  {...register("confirmPassword", { 
                    required: "Confirm password is required",
                    minLength: {value: 6, message: "Minimum length should be 6 characters" }
                  })} 
                  placeholder="Confirm Password" 
                  className="form-control" 
                />
                {errors.confirmPassword && <small className="form-text text-danger">{errors.confirmPassword.message}</small>}
              </Form.Group>
            </>
          )}

          <Form.Group>
            <Button 
              style={{fontSize:"18px"}} 
              className="btn btn-primary btn-block" 
              type="submit" 
              disabled={loading}
            >
              {loading && (
                <Spinner animation="border" role="status" variant="light" size='sm' />
              )}
              {!otpSent ? 'Send OTP' : 'Change Password'}
            </Button>
          </Form.Group>
          
          <small style={{fontSize:"18px"}}>Remember your password? </small>
          <a href="/login" className='text-primary'>Login here</a>
        </form>
      </Container>
    </div>

    {showAlert && showAlert.show && (
      <SweetAlert
        {...(showAlert.type === 'danger' ? { danger: true } : { success: true })}
        title={showAlert.message}
        onConfirm={() => toggleAlert({ show: false, type: 'success', message: '' })}
        confirmBtnCssClass={'btn btn-primary'}
        onCancel={() => toggleAlert({ show: false, type: 'success', message: '' })}
        showConfirm={true}
        focusCancelBtn={false}
        customClass={'i_alert fs-10'}
        timeout={3000}
      />
    )}
    </>
  );
};

export default ForgotPassword;