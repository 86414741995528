import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer id="contact" className="bg-dark text-light">
      <div className="container">
        <div className="f-items default-padding">
          <div className="row" style={{fontSize:"20px"}}>
            {/* Single Item */}
            <div className="col-lg-4 col-md-6 footer-item pr-50 pr-xs-15 pr-md-15">
              <div className="f-item about">
                <img className="logo" src="assets/img/dexpert_white01.png" alt="Logo" style={{ height: '40px' }} /><span style={{position:"absolute",marginTop:"-10px"}}>&#174;</span>

                <div className="f-item newsletter">
                  <p>
                    Elevate your chest X-ray interpretation <br /> with DecXpert
                  </p>

                  <button type="submit"><Link to="/login" style={{fontSize:"20px"}}>Login</Link></button> <br />
                  <button type="submit" className="mt-2">
                    <Link to="/register" style={{fontSize:"20px"}}>Create Account</Link>
                  </button>
                </div>
                <div>
                  <br></br>
                <Link to="/terms" style={{fontSize:"18px", textDecoration:"underline"}}>Terms & Conditions</Link> <br></br>
                <Link to="/refund" style={{fontSize:"18px",textDecoration:"underline"}}>Refund Policy</Link>

                </div>
                <div className="copyright-text mt-40">
                  <p>&copy; Copyright 2023. All Rights Reserved by DecXpert</p>
                </div>
                <div className="footer-social mt-20">
                  {/* <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul> */}
                  <img src="assets/img/dectrocel.jpeg" style={{ height: '80px' }} ></img>
                </div>
              </div>
            </div>
            {/* End Single Item */}

            {/* Single Item */}
            <div className="col-lg-4 col-md-6 item">
              <div className="footer-item contact">
                <h4 className="widget-title" style={{fontSize:"25px"}}>Contact Info</h4>
                <ul>
                  <li>
                    <div className="icon">
                      <i className="fas fa-home"></i>
                    </div>
                    <div className="content">
                      <strong>Address:</strong> MedTech CoE, Third Floor, 
                      <br/>Opposite Admin Block, 
                      <br/>Sanjay Gandhi PostGraduate Institute of Medical Sciences, Raebareli Road, Lucknow, <br/>Uttar Pradesh – 226014

                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="content">
                      <strong>Email:</strong>
                      <a href="mailto:info@validtheme.com">dectrocelhealthcare@gmail.com</a>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fas fa-phone"></i>
                    </div>
                    <div className="content">
                      <strong>Phone:</strong>
                      <a href="tel:9876543210">+91 9807194222</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Single Item */}
            <div className="col-lg-4">
           
              <div className="active-user-info" style={{ marginTop: '20px' }}>
              <h4 className="widget-title" style={{fontSize:"25px"}}>Get Direction</h4>
                {/* <div className="shape-globe">
                  <img className="wow fadeInUp" src="assets/img/globe-light.png" alt="Image Not Found" />
                </div>
                <div className="active-user-banner">
                  <div className="item"></div>
                  <div className="item">
                    <a href="#" className="location-play-button-standard popup-youtube">
                      <i className="fas fa-map-marker" style={{ color: '#ff8a43' }}></i>
                      <div className="effect"></div>
                    </a>
                  </div>
                </div> */}
                <div>
                <div class="embed-responsive">
  <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d579.5864145161905!2d80.94388072504911!3d26.744278522640155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfb006902966f%3A0x4595e1596c031d39!2sNew%20Library%20Building!5e0!3m2!1sen!2sin!4v1706270522585!5m2!1sen!2sin" 
            width="600" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom bg-dark text-light text-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>Copyright &copy; 2023 DecXpert. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
      {/* End Footer Bottom */}

      <div className="foter-shape-right-bottom">
        <img src="assets/img/10.png" alt="Thumb" />
      </div>
    </footer>
  );
};

export default Footer;
