import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { useMyContext } from '../../MyContext';
import { setAuthorization } from '../../services/ApiService';
import './css/scanpage.css';
import ProgressBar from '../../layouts/progressbar';
import { Container, Form, Button,FormCheck,Spinner } from 'react-bootstrap';
import { ApiService } from '../../services/ApiService';
import * as tf from '@tensorflow/tfjs';
import imageSSIM from 'image-ssim';
import jet from './js/js-colormaps';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';

const MODEL_CONFIG = {
  IMAGE_SIZE: 224,
  IMAGE_SCALE: 1024
};

const Scanpage = () => {
  const navigate = useNavigate();
  const { userProfile, setUserProfile, testTypeID, setTestTypeID,creditID, setCreditID,subsID, setSubsID } = useMyContext();
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const [validateError, setValidateError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileFormatError, setFileFormatError] = useState(false);
  const [imageName, setImageName] = useState('');
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSbmt] = useState(false);
  const [finalResult, setFinalResult] = useState(false);
  const [aiResult, setaiResult] = useState([]);
  const [aiReportID, setaiReportID] = useState(0);
  const [TBResult, setTBResult] = useState(false);
  const [invertImage, setInvertImage] = useState(false);

  const [imageElement, setImageElement] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [model, setModel] = useState();
  const [secondModel, setSecondModel] = useState();
  const [resultFindingArr, setResultFindingArr] = useState([]);
  const [validXray, setvalidXray] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const fileInputRef = useRef(null);
  const canvasHiRef = useRef();
  const canvasRef = useRef();
  const canvasRec = useRef();
  const canvasRecimage = useRef();
  const predictionContainerRef = useRef(null);
  const canvasGrad = useRef();
  const canvasGradimage = useRef();

  const toggleAlert = (val) => {
    setShowAlert(val)
}

  useEffect(() => {
    if (testTypeID != 1 && testTypeID != 2 && testTypeID != 3) {
      setTestTypeID(0);
      setValidateError(true);
    }
  }, []);

  useEffect(() => {
    const loadModel = async () => {
      try {
        const modelPath = '/models/ae-chest-savedmodel-64-512/model.json';
        const loadedModel = await tf.loadGraphModel(modelPath.toString());
       // console.log(loadedModel);
        // const modelPath_img = '/models/xrv-all-45rot15trans15scale/model.json';
        // const loadedModel_img = await tf.loadGraphModel(modelPath_img.toString());
        //// console.log(loadedModel_img);

        //printLayerInfo(loadedModel);
        setModel(loadedModel);
       // setSecondModel(loadedModel_img)
      } catch (error) {
        // Log: Error loading the model
        console.error('Error loading the model:', error);
      }
    };
    loadModel();
  }, []);

  useEffect(() => {
    async function loadTF() {
      await tf.ready();
    }

    loadTF();
  }, []);

  useEffect(() => {
    // Display the processed image when the state changes
    if (processedImage) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      tf.browser.toPixels(processedImage, canvas).then(() => {
        // The image is displayed on the canvas without additional modifications
      });
    }
  }, [processedImage]);

  const isImageFile = (file) => {
    const allowedExtensions = ['.jpg', '.jpeg', '.png'];
    const allowedMimeTypes = ['image/jpeg', 'image/png'];
  
    const extension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
    const mimeType = file.type;
  
    return (
      allowedExtensions.includes(`.${extension.toLowerCase()}`) &&
      allowedMimeTypes.includes(mimeType)
    );
  };
  
  const isImageSizeValid = (file) => {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    return file.size <= maxSizeInBytes;
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    setvalidXray('');
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        setImageElement(img);
      };
    };

    if (file) {
      reader.readAsDataURL(file);
    }

    if (file) {
        const isFileTypeValid = isImageFile(file);
        const isSizeValid = isImageSizeValid(file);
    
        if (isFileTypeValid && isSizeValid) {
        setFileFormatError(false);
        setSelectedFile(file);
      } else {
        setFileFormatError(true);
      }
    }
  };
  const handleToggleInvert = (e) => {
    e.preventDefault();
    setInvertImage(!invertImage);
  };

  const onSubmit = async (data) => {
    if (!data || !selectedFile) {
      return false;
    }
    data.testTypeID = testTypeID;
    if(testTypeID == 0 && (creditID == 0 || subsID == 0)){
      //alert('some error occured no TestType detected. Please goto dashboard and try again')
      toggleAlert({ show: true, type: 'danger', message: 'You must have refreshed the page, Please goto Dashboard and start again' });
    }
  
    setLoading(true);
    setFormSbmt(true);
    let checkValidImage = await prepareImage();
    if(checkValidImage == false){
      setvalidXray("This image appears to be outside our training set. We have reservations about its authenticity as a proper X-ray image. Please ensure that the uploaded image is a valid X-ray for accurate analysis");
    }
  
   // console.log("check----->",checkValidImage);
    if(checkValidImage && testTypeID !=0 && (creditID != 0 || subsID != 0)){
      try {
        const formData = new FormData();
        // Append the selected image to the FormData
        formData.append('image', selectedFile);
        // Append form data to the FormData
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
    
        const payloadUrl = 'ai/predict';
        const method = 'POST';
        formData.append('credit_id', creditID);
        formData.append('subs_id', subsID);
        // Use ApiService to make the API call
        const res = await ApiService.fetchData(payloadUrl, method, formData, {
          formType: 'form',
          fileUpload: true,
        });
    
        if (res) {
          if (res.classes) {
            // const tbProbability = res.TBPredictionResult.Score / 100;
            // const tuberculosisObject = {
            //   className: "Tuberculosis",
            //   probability: tbProbability
            // };
        
            // setaiResult([...res.classes, tuberculosisObject]);
            setaiResult(res.classes);
          } else {
            setaiResult(res.classes);
          }
          // console.log(res);
          // setaiResult(res.classes);
          setaiReportID(res.reportID);
          let classes = res.classes;
          // console.log(classes);
           for (const item of classes) {
            if (item.probability > 0.6) {
              // Add the element to the state array
              setResultFindingArr(prevState => [...prevState, item.className]);
            }
          }
          if(res.TBPredictionResult){
            console.log("TB result---",res.TBPredictionResult);
            setTBResult(res.TBPredictionResult);
          }
         
          // Handle successful response, if needed
        } else {
         // console.log('Error');
          // Handle error response, if needed
        }
      } catch (error) {
        console.error('Error submitting data:', error);
        // Handle unexpected error, if needed
      } finally {
        // Introduce a 1-second delay
    await new Promise(resolve => setTimeout(resolve, 100));
        setLoading(false);
        setFormSbmt(false);
        setFinalResult(true);
      }

    }
    else{
      setLoading(false);
      setFormSbmt(false);
    }
  
    
  };
  


  const ErrorUI = () => (
    <p className="text-danger">Invalid file format or Size is grater than 5MB. Please choose a JPG or PNG file.</p>
  );
 

  const FinalResultBars = ({resultArray}) =>{
   // console.log("RESULT ----> ",resultArray);
    // Remove records where className is '' or blank
  const filteredData = resultArray.filter(item => item.className.trim() !== '');

  // Order elements based on className
  const sortedData = filteredData.sort((a, b) => a.className.localeCompare(b.className));

  // Multiply the probability by 100 and round off to eliminate decimal values
  const modifiedData = sortedData.map(item => ({
    className: item.className,
    probability: Math.round(item.probability * 100),
  }));

 // console.log("MODIFIED --->",modifiedData );
  const maxProbabilityLabel = modifiedData.reduce((maxLabel, currentItem) => {
    return currentItem.probability > maxLabel.probability ? currentItem : maxLabel;
  }, { probability: -1 });

 // console.log("MAX PROBABILITY LABEL --->", maxProbabilityLabel);


    return(
      <>
      <div>
      {modifiedData.map((item, index) => (
        <ProgressBar key={index} percentage={item.probability} label={item.className} className={item === maxProbabilityLabel ? 'xray-btn' : ''}/>
      ))}
     <center className='mt-30'> <a className="button-85">
      <i className="fas fa-download mr-2"></i> Download Report
    </a></center>
      </div>
    </>
    )

  }

  const TBResultFinal = ({resultScore}) =>{
   // console.log("RESULT ----> ",resultScore);

    return(
      <>
      <div>
        <ProgressBar key='TB' percentage={resultScore} label="Tuberculosis" className={resultScore == 99 ? 'xray-btn' : 'tb-btn'}/>
      </div>
    </>
    )

  }

  const prepareImageResizeCrop = (imgElement, size) => {
    const orig_width = imgElement.width;
    const orig_height = imgElement.height;
  
    let targetWidth, targetHeight;
  
    if (orig_width < orig_height) {
      targetWidth = size;
      targetHeight = Math.floor((size * orig_height) / orig_width);
    } else {
      targetHeight = size;
      targetWidth = Math.floor((size * orig_width) / orig_height);
    }
  
   // console.log(`img wxh: ${orig_width}, ${orig_height} => ${targetWidth}, ${targetHeight}`);
  
    const img = tf.browser.fromPixels(imgElement).toFloat();
  
    const hOffset = Math.floor(img.shape[1] / 2 - size / 2);
    const wOffset = Math.floor(img.shape[0] / 2 - size / 2);
  
    const imgCropped = img.slice([wOffset, hOffset], [size, size]);
  
    const imgNormalized = imgCropped.mean(2).div(255);
  
    return imgNormalized;
  };

  const prepareImage = async () => {
    if (imageElement) {
      // const thispred = [{ img_original: null, img_highres: null, img_resized: null, img_input: null }];

      // thispred[0].img_original = tf.browser.fromPixels(imageElement).toFloat();

      // thispred[0].img_highres = prepareImageResizeCrop(imageElement, Math.max(imageElement.width, imageElement.height));
      // thispred[0].img_resized = prepareImageResizeCrop(imageElement, MODEL_CONFIG.IMAGE_SIZE);
      // thispred[0].img_input = thispred[0].img_resized.mul(2).sub(1).mul(tf.scalar(MODEL_CONFIG.IMAGE_SCALE));

      // let highResImageTensor = thispred[0].img_highres;
      // const hicanvas2 = canvasHiRef.current;
      // const tf22 = await tf.browser.toPixels(highResImageTensor,hicanvas2);	
      // // Use hicanvas2 for further processing



     // setProcessedImage(thispred[0].img_resized); // Update the state with the processed image
      const imgSmall = document.createElement('img');
      imgSmall.src = imageElement.src;
      imgSmall.width = 64;
      imgSmall.height = 64;

      let {recInput, recErr, rec} = tf.tidy(() => {
        const img = tf.browser.fromPixels(imgSmall).toFloat();
        const normalized = img.div(tf.scalar(255));
        const aebatched = normalized.mean(2).reshape([1, 1, 64, 64])

        const rec = model.predict(aebatched)
        const recErr = aebatched.sub(rec).abs()
    
        return {recInput:aebatched, recErr: recErr, rec: rec};
      });

      const recScore = recErr.mean().dataSync()
     // console.log("recScore" + recScore);

      const canvas_a = canvasRec.current;
		let layer = recInput.reshape([64,64])
		await tf.browser.toPixels(layer.div(2).add(0.5),canvas_a);

    const canvas_b = canvasRecimage.current;
		layer = rec.reshape([64,64])
		await tf.browser.toPixels(layer.div(2).add(0.5),canvas_b);

    // compute ssim
    let canvas = canvas_a
    let a = {width: canvas.width, height: canvas.height, data: canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height).data, channels: 4, canvas: canvas}
    canvas = canvas_b
    let b = {width: canvas.width, height: canvas.height, data: canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height).data, channels: 4, canvas: canvas}

    const ssim = imageSSIM.compare(a, b, 8, 0.01, 0.03, 8)
		//console.log("ssim " + JSON.stringify(ssim));

    let score = "recScore:" + parseFloat(recScore).toFixed(2)  + ", ssim:" + ssim.ssim.toFixed(2) 
    let can_predict = ssim.ssim > 0.60

   // console.log(score);
   // console.log(can_predict);
    return can_predict;
    //setvalidXray(can_predict);


      // let score = "recScore:" + parseFloat(recScore).toFixed(2)  + ", ssim:" + ssim.ssim.toFixed(2) ;
      // console.log("score",score);

      // let can_predict = ssim.ssim > 0.60;
      // console.log("Predict",can_predict);



     
    }
  };

  const UploadedImageUI = () =>{
    return(
        <>
        <div className="row">
       
            <div className="col-md-6 col-lg-5">
            <div className={`xrayImage ${loading ? 'scanning' : ''} ml-50`}>
                <img src={URL.createObjectURL(selectedFile)} alt="Uploaded" style={{ filter: invertImage ? 'invert(1)' : 'none' }} />
                <canvas ref={canvasHiRef} style={{display:"none"}}></canvas>
                <canvas ref={canvasRef} style={{display:"none"}}></canvas>
              
                <canvas id="can1" ref={canvasRec} width={64} height={64} style={{display:"none"}}></canvas>
                <canvas id="can2" ref={canvasRecimage} width={64} height={64} style={{display:"none"}}></canvas>
                <canvas ref={canvasGrad} style={{display:"none"}}></canvas>
               
    
     
                
                {/* <canvas class="layer inputimage_rec" style="display:none;"></canvas>
				        <canvas class="layer recimage" style="display:none;"></canvas> */}
            </div>
            <div className='mt-20'>
              <center>
              <a href="#" onClick={(e)=>handleToggleInvert(e)} className='button-50 mt-30'>
              Invert Xray Image
              </a>
              {/* <button onClick={prepareImage}>Process Image</button> */}
              {/* <button onClick={()=>computeGradsReal(16)}>Process Image Grad</button> */}
              </center>
       
      </div>
            </div>
            <div className="col-md-6 col-lg-6">
              {(!finalResult)?<> <div class="card mr-10">
            <div class="card-header bg-primary text-white">
                    Confirm Patient Details
            </div>
						<div class="card-body">
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
							<div class="row mb-3">
								<div class="col-sm-3">
									<h6 class="mb-0">Patient Name</h6>
								</div>
								<div class="col-sm-9 text-secondary">
									<input type="text" class="form-control" {...register("name", { required: true })} placeholder='Fullname' defaultValue={userProfile.fullname}/>
                  {errors.name?.type === "required" && <small className="form-text text-danger">Patient name is required</small>}
								</div>
							</div>
							<div class="row mb-3">
								<div class="col-sm-3">
									<h6 class="mb-0">Mobile number</h6>
								</div>
								<div class="col-sm-9 text-secondary">
									<input type="number" class="form-control" {...register("mobile", { required: "mobile number is required",maxLength: {value: 10,message: "enter only 10 digit mobile number" },minLength: {value: 10,message: "Enter at least 10 digits for the mobile number"} })}
                   placeholder="Mobile Number" defaultValue={userProfile.mobile}/>
                  {errors.mobile && <small className="form-text text-danger">{errors.mobile.message}</small>}
								</div>
							</div>
                            <div class="row mb-3">
								<div class="col-sm-3">
									<h6 class="mb-0">Patient Age</h6>
								</div>
								<div class="col-sm-9 text-secondary">
									<input type="number" class="form-control" {...register("age", { required: "age is required",maxLength: {value: 2,message: "enter only 2 digit" } })}
                   placeholder="age"/>
                  {errors.age && <small className="form-text text-danger">{errors.age.message}</small>}
								</div>
							</div>
                            <div class="row mb-3">
								<div class="col-sm-3">
									<h6 class="mb-0">Patient Gender</h6>
								</div>
								<div class="col-sm-9 text-secondary">
                    <select class="form-control" {...register("gender", { required: true })}>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
								</div>
							</div>
                            <div class="row mb-3">
								<div class="col-sm-3">
									
								</div>
								<div class="col-sm-9 text-secondary">
                <Button className='btn btn-colour-2' type="submit" disabled={loading}>
              {loading ? (
              <Spinner animation="border" role="status" variant="light" size='sm'>
              </Spinner>
            ) : (
              <></>
            )}Submit
            </Button>
            <br></br>
            <span className='text-danger'><b>{validXray}</b></span>
								</div>
							</div>
                        </form>
							
           
            </div>
        </div></>:<>
        {/* {(TBResult)? <TBResultFinal resultScore={aiResult.Score}/> : <></>}
        <FinalResultBars resultArray={aiResult}/>
        <button onClick={handleUIButtonClick}>Show UI Results</button>
        <div ref={predictionContainerRef}></div> */}
        <ShowProbResults aiResult={aiResult} />
        </>}
       
        </div>
        </div>
       
        </>
    )
  }

  function ClassNameComponent({ data }) {
    // Filter out items with probability greater than 0.6 and non-empty class names
   const filteredData = data.filter(item => item.probability > 0.6 && item.className.trim() !== '');
   const classNames = filteredData.map(item => item.className);
   if (classNames.length === 0) {
    return 'The artificial intelligence software did not identify any significant findings.';
   }
 
   if (classNames.length === 1) {
     return <span>The artificial intelligence software has predicted the most likely diagnosis is {classNames[0]}</span>;
   }
   const formattedClassNames =
     classNames.slice(0, -1).join(', ') + ' and ' + classNames.slice(-1);
 
   return <span> The artificial intelligence software has predicted the most likely diagnosis is {formattedClassNames}</span>;
   }

  const ShowProbResults = ({ aiResult }) => {
    console.log("AI-Result",aiResult);
    const classes = aiResult;
  
    const tableRows = classes.map((classItem, i) => {
      if (i >= 0 && classItem.className === "") {
        return null;
      }
  
      return (
        <tr key={i} style={{padding:"5px"}}>
          <td style={{whiteSpace: 'nowrap', width:"1%"}}>
            <span className={(classItem.probability > 0.6)?'xray-btn':'small'}>
            {classItem.className}
            </span>
           
            </td>
          <td style={{whiteSpace: 'nowrap', width:"1%"}}>
            {/* {i !== -1 && classItem.probability > 0.6 && (
              <button className="explain-btn-custom btn btn-info">explain</button>
            )} */}
          </td>
          <td className={`cell-custom gradient-custom ${i === -1 ? '' : 'active'}`} style={{border:"2px solid white"}}>
              <div id="pot" className='animated-marker' style={{ position: 'relative', width: '100%', textAlign: 'left', borderBottomStyle: 'solid', borderColor: 'white', borderWidth: '2' }}>
                <span className="marker fa fa-asterisk " style={{ marginLeft: `calc(${classItem.probability * 100}% - 7px)`, fontWeight: '800',fontSize:"15px" }}>
              <span style={{marginLeft:"4px"}}>{`${(classItem.probability * 100).toFixed(2)}%`}</span>
             
            </span>
              </div>
           
          </td>
        </tr>
      );
    });
  
    return (
      <>      <table className='table table-sm table-borderless' cellPadding={0} cellSpacing={0} style={{ width: '100%', minWidth: '220px' }}>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td style={{ textAlign: 'left' }}>
              <div className="health-status">
              <span style={{ float: 'left', fontSize: '14px', fontWeight:"bolder" }}>Healthy &#128512;</span>
              <hr className='gradient-custom' style={{ margin: '0', width: '70%',height:"6px" }} />
              <span style={{ float: 'left', fontSize: '14px', fontWeight:"bolder" }}>Risky &#128552;</span>
              </div>
            </td>
          </tr>

          {tableRows}</tbody>
      </table>
      <center className='mt-30'> 
      {/* <span className='mb-10'>AI software has predicted - 
            <b>{resultFindingArr[0]}</b>
            <b>{(resultFindingArr[0])?'and ':''}</b> 
             <b>{(TBResult.TB)?`Tuberculosis with ${TBResult.Score}% score`:''}</b>
       </span> */}
        <p> 
           <span style={{"padding-left":"10px"}}><b>
           <ClassNameComponent data={aiResult} />
           </b>
           {/* <b>{(tbResult.TB)?`Tuberculosis with ${tbResult.Score}% score`:''}</b> */}
          </span></p>
       
       <br/>
      <a className="button-85" onClick={()=>{downloadreport(aiReportID)}}>
      <i className="fas fa-download mr-2"></i> Download Report
    </a></center>
    <br/>
    <br/>
      </>
    );
  };

  const thispred = useRef({
    img_original: null,
    img_highres: null,
    img_resized: null,
    img_input: null,
    grads: {},
  });

  const makeColor = (data) => {
    for (var i = 0; i < data.length; i += 4) {
      var color = interpolateLinearly(data[i] / 255, jet);
      data[i] = Math.round(255 * color[0]); // Invert Red
      data[i + 1] = Math.round(255 * color[1]); // Invert Green
      data[i + 2] = Math.round(255 * color[2]); // Invert Blue
    }
  };

  function makeTransparent(pix) {
    //var imgd = ctx.getImageData(0, 0, imageWidth, imageHeight),
    //pix = imgd.data;
  
    for (var i = 0, n = pix.length; i <n; i += 4) {
  //		var r = pix[i],
      let g = pix[i+1];
  //		b = pix[i+2];
  
      if (g < 20){ 
        // If the green component value is higher than 150
        // make the pixel transparent because i+3 is the alpha component
        // values 0-255 work, 255 is solid
        pix[i + 3] = 0;
      }
    }
    //ctx.putImageData(imgd, 0, 0);​
  }

function applyColorMap(value, jet) {
  var color = interpolateLinearly(value / 255, jet);
    // Ensure alpha (transparency) is set to 0
// Check if the color is yellow
const isYellow = color[0] > 0.9 && color[1] > 0.9 && color[2] < 0.1;

// Return color with full transparency for non-yellow colors
return [
    isYellow ? Math.round(255 * color[2]) : 0, // Red
    isYellow ? Math.round(255 * color[1]) : 0, // Green
    isYellow ? Math.round(255 * color[0]) : 0, // Blue
    isYellow ? 255 : 0                          // Set full transparency for non-yellow, 0 for yellow
];
}

function applyColorMapToImageData(imageData, jet) {
  for (var i = 0; i < imageData.data.length; i += 4) {
      var colorMapResult = applyColorMap(imageData.data[i], jet);
      imageData.data[i] = colorMapResult[0];
      imageData.data[i + 1] = colorMapResult[1];
      imageData.data[i + 2] = colorMapResult[2];
      imageData.data[i + 3] = colorMapResult[3];
  }
}


const colorMap = [
  [0.0, [255, 0, 0]],   // Red
  [0.5, [0, 255, 0]],   // Green
  [1.0, [0, 0, 255]],   // Blue
];



  const enforceBounds = (x) => {
    if (x < 0) {
      return 0;
    } else if (x > 1) {
      return 1;
    } else {
      return x;
    }
  };

  const interpolateLinearly = (x, values) => {
    // Split values into four lists
    var x_values = [];
    var r_values = [];
    var g_values = [];
    var b_values = [];
    for (var i in values) {
      x_values.push(values[i][0]);
      r_values.push(values[i][1][0]);
      g_values.push(values[i][1][1]);
      b_values.push(values[i][1][2]);
    }
    var i = 1;
    while (x_values[i] < x) {
      i = i + 1;
    }
    i = i - 1;
    var width = Math.abs(x_values[i] - x_values[i + 1]);
    var scaling_factor = (x - x_values[i]) / width;
    // Get the new color values though interpolation
    var r = r_values[i] + scaling_factor * (r_values[i + 1] - r_values[i]);
    var g = g_values[i] + scaling_factor * (g_values[i + 1] - g_values[i]);
    var b = b_values[i] + scaling_factor * (b_values[i + 1] - b_values[i]);
    return [enforceBounds(r), enforceBounds(g), enforceBounds(b)];
  };

  const computeGradsReal = async (idx) => {
    let img_input;
  
    // Cache computation
    {
      const layer = tf.tidy(() => {
        const chestgrad = tf.grad((x) =>
          secondModel.predict(x).reshape([-1]).gather(idx)
        );
  
        let img_resized = prepareImageResizeCrop(
          imageElement,
          MODEL_CONFIG.IMAGE_SIZE
        );
        img_input = img_resized.mul(2).sub(1).mul(tf.scalar(MODEL_CONFIG.IMAGE_SCALE));
  
        const batched = img_input.reshape([1, 1, MODEL_CONFIG.IMAGE_SIZE, MODEL_CONFIG.IMAGE_SIZE]);
        const grad = chestgrad(batched);
  
        const layer = grad.mean(0).abs().max(0);
        return layer.div(layer.max());
      });
  
      // Display grad image
      const canvas = canvasGrad.current;
      await tf.browser.toPixels(layer, canvas);
  
      const ctx = canvas.getContext("2d");
  
      const d = ctx.getImageData(0, 0, canvas.width, canvas.height);
     
      // makeColor(d.data);
      // makeTransparent(d.data);
      const pixelData = d.data;

// Apply color map
for (let i = 0; i < pixelData.length; i += 4) {
    const value = pixelData[i] / 255;  // Assuming the value is in the red channel
    const color = applyColorMap(value, colorMap);
    pixelData[i] = color[0];
    pixelData[i + 1] = color[1];
    pixelData[i + 2] = color[2];
}

// Make pixels transparent based on a threshold
const transparencyThreshold = 0.7;
makeTransparent(pixelData, transparencyThreshold);
// Render modified image data back to the canvas
ctx.putImageData(d, 0, 0);

 
  
      // Assuming img_input is an array where each element is an image data
      img_input[idx] = d;
    }
  
    // Render the image data on canvas
    const d = img_input[idx];
    const canvas = canvasGrad.current;
  // console.log('Image Data Before Rendering:', d);
    const ctx = canvas.getContext("2d");
    ctx.putImageData(d, 0, 0);
  };

  const downloadreport = (reportID) =>{
    navigate(`/dashboard/report?rid=${reportID}`);
  }
  return (
    <>
     {/* Hidden file input */}
     <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange}/>
    <div className="container-fluid">
      {(!finalResult)?<> <div class="alert alert-warning alert-dismissible fade show">
        <strong>Warning!</strong> Please do not refresh/reload the page during the test.
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
    </div>
    <div class="d-sm-flex align-items-center mb-4">
    <h4 className="h mb-0 text-gray-800 pl-10">To Start {(testTypeID==1)?"Digital Pathology":(testTypeID==2)?"TB ":(testTypeID==3)?"Digital Pathology + TB ":""}Scan </h4>
    <button className='btn-sm btn-colour-1 ml-10' onClick={handleButtonClick}>upload Xray Image</button>
    </div>
    </>:<>
    <div class="alert alert-success alert-dismissible fade show">
        <strong>Success!</strong> Report Successfully Generated ! To start a new test <a href="/dashboard" className='text-primary'>click here</a> 
        
    </div></>}
    
  
    </div>

      {selectedFile && <UploadedImageUI/>}
   

      {fileFormatError && <ErrorUI />}
      {(() => {
                if (showAlert && showAlert.show && showAlert.type == "danger") {
                    return (
                        <SweetAlert
                            danger
                            title={showAlert.message}
                            onConfirm={() => toggleAlert({ show: false, type: 'success', message: '' })}
                            confirmBtnCssClass={'btn btn-primary'}
                            onCancel={() => toggleAlert({ show: false, type: 'success', message: '' })}
                            showConfirm={true}
                            focusCancelBtn={false}
                            customClass={'i_alert fs-10'}
                            timeout={3000}
                        />
                    )
                }else if (showAlert && showAlert.show && showAlert.type == "success") {
          return (
            <SweetAlert
              success
              title={showAlert.message}
              onConfirm={() =>
                toggleAlert({ show: false, type: "success", message: "" })
              }
              confirmBtnCssClass={"btn_15"}
              onCancel={() =>
                toggleAlert({ show: false, type: "success", message: "" })
              }
              showConfirm={true}
              focusCancelBtn={false}
              customClassName={"air_alert"}
              timeout={3000}
            />
          );
        }
            })()}
    </>
  );
};

export default Scanpage;
