import React, { useState } from "react";
import HelperModal from '../helpers/modal';
import './css/pricing.css';

const PricingComponent = () => {
  const [activeTab, setActiveTab] = useState("monthly");
  const [openModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null)
  const [modalData, setModalData] = useState({});
  const [payplanID, setPayplanID] = useState(null);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const hideModal = () => {
    setModalType(null);
    setShowModal(false);
};
const showModal = async (modalName = null, data = null, modalHeading = null) => {
  if (modalName == null) {
    return false;
  }
  setModalData({})
  switch (modalName) {
    case "payment_link":
    if (data != null) {
        setPayplanID(data)
        setModalType(modalName);
        setShowModal(true);
      }
    break;
  }
};

  return (
    <>
    <section class="pricing">
    <div className="container p-0">
      <h1 className="h3 text-center">Plans &amp; Pricing</h1>
    
      <div className="row">
        <div className="col-md-10 col-lg-12 col-xl-10 mx-auto">

          <div className="row justify-content-center mt-3 mb-2">
            <div className="col-auto">
              <nav className="nav btn-group">
                <a href="#m" className={`btn btn-outline-primary ${ activeTab === "monthly" ? "active" : "" }`}
                  onClick={() => handleTabChange("monthly")} > Digital Pathology + TB Scans </a>
                <a href="#a" className={`btn btn-outline-primary ${ activeTab === "annual" ? "active" : "" }`}
                  onClick={() => handleTabChange("annual")} >
                  Digital Pathology OR TB Scans
                </a>
              </nav>
            </div>
          </div>
         
          <div className="tab-content">
            {/* Monthly Tab */}
            <div className={`tab-pane fade show ${
                activeTab === "monthly" ? "active" : "" }`}  id="monthly">

              <div className="row py-4">
                
                {/* Basic Plan */}
                <div className="col-md-3 mb-3 mb-md-0">
                  <div class="card mb-5 mb-lg-0">
           <div class="card-body">
            <h5 class="card-title text-muted text-uppercase text-center">Basic Plan</h5>
            <h6 class="text-center" style={{fontSize:"20px"}}><sup>₹</sup>22 <sub class="period">/ Scan</sub></h6>
            <hr/>
            <center><h6>Includes:</h6></center>
            
            <ul className="list-unstyled text-center">
                  <li><i className="fas fa-check-circle"></i> 300 Scans</li>
                  <li><i className="fas fa-check-circle"></i> 6600 per month</li>
                       
                      </ul>
            <div class="d-grid">
              <button onClick={(e) => showModal('payment_link', '1', e)} className="btn circle mt-25 btn-sm btn-border effect">Purchase</button>
            </div>
          </div>
        </div>
                </div>
                {/* Plus Plan */}
                <div className="col-md-3 mb-3 mb-md-0">
                  <div class="card mb-5 mb-lg-0">
          <div class="card-body">
            <h5 class="card-title text-muted text-uppercase text-center">Standard Plan</h5>
            <h6 class="text-center" style={{fontSize:"20px"}}><sup>₹</sup>20 <sub class="period">/ Scan</sub></h6>
            <hr/>
            <center><h6>Includes:</h6></center>
            
            <ul className="list-unstyled text-center">
                  <li><i className="fas fa-check-circle"></i> 900 Scans</li>
                  <li><i className="fas fa-check-circle"></i> ₹ 18000 per month</li>
                  <li><i className="fas fa-check-circle"></i> Client Services Included</li>
                       
                      </ul>
            <div class="d-grid">
            <button onClick={(e) => showModal('payment_link', '2', e)} className="btn circle mt-25 btn-sm btn-border effect">Purchase</button>
            </div>
          </div>
        </div>
                </div>

                <div className="col-md-3 mb-3 mb-md-0">
                  <div class="card mb-5 mb-lg-0">
          <div class="card-body">
            <h5 class="card-title text-muted text-uppercase text-center">Premium Plan</h5>
            <h6 class="text-center" style={{fontSize:"20px"}}><sup>₹</sup> 15<sub class="period">/ Scan</sub></h6>
            <hr/>
            <center><h6>Includes:</h6></center>
            
            <ul className="list-unstyled text-center">
                  <li><i className="fas fa-check-circle"></i> 3000 Scans</li>
                  <li><i className="fas fa-check-circle"></i> ₹45000 per Month</li>
                  <li><i className="fas fa-check-circle"></i> Client Services Included</li>
                       
                      </ul>
            <div class="d-grid">
            <button onClick={(e) => showModal('payment_link', '3', e)} className="btn circle mt-25 btn-sm btn-border effect">Purchase</button>
            </div>
          </div>
        </div>
                </div>

                <div className="col-md-3 mb-3 mb-md-0">
                  <div class="card mb-5 mb-lg-0">
          <div class="card-body">
            <h5 class="card-title text-muted text-uppercase text-center">Tele-radiology</h5>
            <h6 class="text-center" style={{fontSize:"20px"}}><sup>₹</sup>12 <sub class="period">/ Scan</sub></h6>
            <hr/>
            <center><h6>Includes:</h6></center>
            
            <ul className="list-unstyled text-center">
            <li><i className="fas fa-check-circle"></i> 60,000 Scan</li>
                 <li><i className="fas fa-check-circle"></i> ₹ 7,20,000 per Month</li>
                  <li><i className="fas fa-check-circle"></i> Client Services Included</li>
                       
                      </ul>
            <div class="d-grid">
            <button onClick={(e) => showModal('payment_link', '4', e)} className="btn circle mt-25 btn-sm btn-border effect">Purchase</button>
            </div>
          </div>
        </div>
                </div>
              </div>
           
            </div>

            {/* Annual Tab */}
            <div
              className={`tab-pane fade ${
                activeTab === "annual" ? "show active" : ""
              }`}
              id="annual"
            >
              {/* Content for Annual Tab */}
              <div className="row py-4">
                {/* Basic Plan */}
                <div className="col-md-3 mb-3 mb-md-0">
                  <div class="card mb-5 mb-lg-0">
          <div class="card-body">
            <h5 class="card-title text-muted text-uppercase text-center">Basic Plan</h5>
            <h6 class="text-center" style={{fontSize:"20px"}}><sup>₹</sup>20 <sub class="period">/ Scan</sub></h6>
            <hr/>
            <center><h6>Includes:</h6></center>
            
            <ul className="list-unstyled text-center">
            <li><i className="fas fa-check-circle"></i> 300 Scans</li>
                <li><i className="fas fa-check-circle"></i> ₹ 6000 per Month</li>
                       
                      </ul>
            <div class="d-grid">
            <button onClick={(e) => showModal('payment_link', '5', e)} className="btn circle mt-25 btn-sm btn-border effect">Purchase</button>
            </div>
          </div>
        </div>
                </div>


                 {/* Basic Plan */}
                 <div className="col-md-3 mb-3 mb-md-0">
                  <div class="card mb-5 mb-lg-0">
          <div class="card-body">
            <h5 class="card-title text-muted text-uppercase text-center">Standard Plan</h5>
            <h6 class="text-center" style={{fontSize:"20px"}}><sup>₹</sup>18 <sub class="period">/ Scan</sub></h6>
            <hr/>
            <center><h6>Includes:</h6></center>
            
            <ul className="list-unstyled text-center">
            <li><i className="fas fa-check-circle"></i> 900 Scans</li>
                <li><i className="fas fa-check-circle"></i> ₹ 16200 per Month</li>
                <li><i className="fas fa-check-circle"></i> Client Services Included</li>
                       
                      </ul>
            <div class="d-grid">
            <button onClick={(e) => showModal('payment_link', '6', e)} className="btn circle mt-25 btn-sm btn-border effect">Purchase</button>
            </div>
          </div>
        </div>
                </div>


                 {/* Basic Plan */}
                 <div className="col-md-3 mb-3 mb-md-0">
                  <div class="card mb-5 mb-lg-0">
          <div class="card-body">
            <h5 class="card-title text-muted text-uppercase text-center">Premium Plan</h5>
            <h6 class="text-center" style={{fontSize:"20px"}}><sup>₹</sup>12 <sub class="period">/ Scan</sub></h6>
            <hr/>
            <center><h6>Includes:</h6></center>
            
            <ul className="list-unstyled text-center">
            <li><i className="fas fa-check-circle"></i> 3000 Scans</li>
                <li><i className="fas fa-check-circle"></i> ₹36000 per Month</li>
                <li><i className="fas fa-check-circle"></i> Client Services Included</li>
                       
                      </ul>
            <div class="d-grid">
            <button onClick={(e) => showModal('payment_link', '7', e)} className="btn circle mt-25 btn-sm btn-border effect">Purchase</button>
            </div>
          </div>
        </div>
                </div>


                 {/* Basic Plan */}
                 <div className="col-md-3 mb-3 mb-md-0">
                  <div class="card mb-5 mb-lg-0">
          <div class="card-body">
            <h5 class="card-title text-muted text-uppercase text-center">Tele-radiology</h5>
            <h6 class="text-center" style={{fontSize:"20px"}}><sup>₹</sup>10  <sub class="period">/ Scan</sub></h6>
            <hr/>
            <center><h6>Includes:</h6></center>
            
            <ul className="list-unstyled text-center">
            <li><i className="fas fa-check-circle"></i> 60,000 Scan</li>
               <li><i className="fas fa-check-circle"></i> ₹ 6,00,000 per Month</li>
                <li><i className="fas fa-check-circle"></i> Client Services Included</li>
                       
                      </ul>
            <div class="d-grid">
            <button onClick={(e) => showModal('payment_link', '8', e)} className="btn circle mt-25 btn-sm btn-border effect">Purchase</button>
            </div>
          </div>
        </div>
                </div>
              </div>
            </div>
          </div>
         
          {/* Rest of the component */}
        </div>
      </div>
     
    </div>
    </section>
    {(() => {
              if (modalType && modalType != "" && modalType != null) {
                if (modalType == "payment_link") {
                  return (
                    <HelperModal
                      show={openModal}
                      modalType={modalType}
                      hideModal={hideModal}
                      modalData={{payplanID:payplanID}}
                      formSubmit={()=>{}}
                    />
                  );
                }
              }
              
          })()}
    </>

  );
};

export default PricingComponent;
