import React from 'react';
import styles from './css/team.module.css';

const TeamGallery = () => {
  return (
    <div id="blog" className="blog-area home-blog default-padding bottom-less">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h2 className="heading">Join us in Reshaping the Future of Radiology in
              Respiratory Medicine</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
  
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h3>Our Technical Team</h3>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div className={styles.card}>
              <img src="assets/img/Saumya.jpeg" className="card-img-top rounded" alt="Saumya Shukla"/>
              <div className={styles.cardBody}>
                <h5 className={styles.cardTitle}>Saumya Shukla</h5>
                <p className={styles.cardText}>CEO, Dectrocel</p>
              </div>
            </div>
          </div>
          
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div className={styles.card}>
              <img src="assets/img/Ankit.jpeg" className="card-img-top rounded" alt="Dr. Ankit Shukla"/>
              <div className={styles.cardBody}>
                <h5 className={styles.cardTitle}>Dr. Ankit Shukla</h5>
                <p className={styles.cardText}>Technical Director, Dectrocel</p>
              </div>
            </div>
          </div>
          
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div className={styles.card}>
              <img src="assets/img/NikhilMisra.jpeg" className="card-img-top rounded" alt="Nikhil Mishra"/>
              <div className={styles.cardBody}>
                <h5 className={styles.cardTitle}>Nikhil Mishra</h5>
                <p className={styles.cardText}>CTO, Dectrocel</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-12 text-center">
            <h3>Our Medical Team</h3>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div className={styles.card}>
              <img src="assets/img/DrAlokNath.jpeg" className="card-img-top rounded" alt="Dr Alok Nath"/>
              <div className={styles.cardBody}>
                <h5 className={styles.cardTitle}>Dr Alok Nath</h5>
                <p className={styles.cardText}>Head of Department, SGPGI Hospital, Lucknow <br/><br/></p>
              </div>
            </div>
          </div>
          
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div className={styles.card}>
              <img src="assets/img/DrZiaHashim.jpeg" className="card-img-top rounded" alt="Dr Zia Hashim"/>
              <div className={styles.cardBody}>
                <h5 className={styles.cardTitle}>Dr Zia Hashim</h5>
                <p className={styles.cardText}>Associate Professor, SGPGI Hospital, Lucknow <br/><br/></p>
              </div>
            </div>
          </div>
          
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div className={styles.card}>
              <img src="assets/img/Dr.Ajmal-Khan.jpg" className="card-img-top rounded" alt="Dr. Ajmal Khan"/>
              <div className={styles.cardBody}>
                <h5 className={styles.cardTitle}>Dr. Ajmal Khan</h5>
                <p className={styles.cardText}>Additional Professor, SGPGI Hospital, Lucknow <br/><br/></p>
              </div>
            </div>
          </div>
          
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div className={styles.card}>
              <img src="assets/img/Dr.Prasanth-Areekkara-Poduvattil.jpg" className="card-img-top rounded" alt="Dr. Prasanth Areekkara Poduvattil"/>
              <div className={styles.cardBody}>
                <h5 className={styles.cardTitle}>Dr. Prasanth Areekkara Poduvattil</h5>
                <p className={styles.cardText}>Associate Professor, SGPGI Hospital, Lucknow</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamGallery;