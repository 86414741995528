import CryptoJS from 'crypto-js';
import cookieCutter from 'cookie-cutter'
import Cookies from 'universal-cookie';
import { ApiService, setAuthorization } from '../../services/ApiService';

export const encryptData = (data = '') => {
    if (data === '') {
      return data;
    }
  
    const iv = process.env.REACT_APP_ENCRYPT_IV.slice(0, 16);
    const encKey = process.env.REACT_APP_ENCRYPT_KEY.slice(0, 16);
  
    // const encrypted = CryptoJS.AES.encrypt(data, encKey, {
    //   iv: CryptoJS.enc.Utf8.parse(iv),
    //   mode: CryptoJS.mode.CBC,
    //   padding: CryptoJS.pad.Pkcs7,
    // });
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), encKey);
  
    return encrypted.toString();
  };
  
  export const decryptData = (token = '') => {
    if (token === '') {
      return token;
    }
  
    const iv = process.env.REACT_APP_ENCRYPT_IV.slice(0, 16);
    const encKey = process.env.REACT_APP_ENCRYPT_KEY.slice(0, 16);
  
    // const decrypted = CryptoJS.AES.decrypt(token, encKey, {
    //   iv: CryptoJS.enc.Utf8.parse(iv),
    //   mode: CryptoJS.mode.CBC,
    //   padding: CryptoJS.pad.Pkcs7,
    // });
    try {
      const bytes = CryptoJS.AES.decrypt(token, encKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  
      // Try to parse the decrypted data as JSON
      try {
        return JSON.parse(decryptedData);
      } catch (jsonError) {
        console.error('Error parsing decrypted JSON data:', jsonError);
        return null;
      }
    } catch (error) {
      console.error('Error decrypting data:', error);
      return null;
    }
    
  
   // return decryptedData
  };

export const GetCookie = (cookieName = '') => {
  if (!cookieName || cookieName == '') {
    return false;
  }

  // Get a cookie
  let getCookie = cookieCutter.get(cookieName)
  return getCookie ? decryptData(getCookie) : false
}

export const SetCookie = (cookieName = '', value, options = null, encrypt = true, setDefaultOpts = true) => {
  if (!cookieName || cookieName == '' || !value || value == '') {
    return false;
  }
  if (options == null) {
    options = {}
    options.path = '/'
  } else {
    if (!options.path) {
      options.path = '/'
    }
  }
  if (setDefaultOpts) {
    if (!options.secure) {
      options.secure = process.env.HTTPS ? true : false;
    }
    if (!options.sameSite) {
      options.sameSite = "Strict";
    }
  }
  let now = new Date()
  options.expires = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 60)
  // Set a cookie
  let data = encrypt ? encryptData(value) : value;
  let cookies = new Cookies();
  let setCookie = cookies.set(cookieName, data, options)
  return setCookie ? setCookie : false
}

export const DelCookie = (cookieName = '') => {
  if (!cookieName || cookieName == '') {
    return false;
  }

  // Delete a cookie
  let setCookie = cookieCutter.set(cookieName, '', { expires: new Date(0), path: '/' })
  return setCookie ? setCookie : false

}

export const GetRandomColor = () => {
  var letters = "0123456789ABCDEF";
  let colors = ['#7d7d7d94', '#e31e1e8c', '#b1d8ec', '#00b8ff', '#309e7b', '#ffc107']
  let selColor = colors[Math.floor(Math.random() * colors.length)]
  return "#000"
}

export const GetInitials = (str = '') => {
  if (str == '') {
    return ''
  }
  let name = str.split(' ');
  let fInitial = name[0] ? name[0].substr(0, 1) : '#';
  let lInitial = name[1] ? name[1].substr(0, 1) : '';
  let result = `${fInitial} ${lInitial}`
  return result
}

export const IsAuthenticated = (fetchUser = false) => {
    let userData = GetCookie('currentUser')
    userData  = userData ? JSON.parse(userData) : false;
    let result = {isLoggedIn : false,user: null,token: ''}
    if(userData){
        let otpVerified = userData.otpVerified  ? userData.otpVerified : true
        result.isLoggedIn = true;
        result.user = fetchUser ? userData : null;
        result.token = fetchUser ? userData.accessToken : '';
        result.otpVerified = otpVerified;
    }
    return result
}

export const RedirectToLogin = (errCode = 200) =>{
    // const navigate = useNavigate()
    const loggedInUser = IsAuthenticated(true);
    // const {updateData} = useContext(AuthContext)
  //  if (loggedInUser.isLoggedIn) {
        let del = DelCookie("currentUser");
        let auth_roles = DelCookie("account_type");
        localStorage.removeItem("currentUserSession");
        // updateData("clear");
        if (del) {
            let url = `/login`
            if(errCode == 403){
                url += `?error=sess_exp`
            }
            // navigate(url)
            document.location.href=url
        }
  //  }
}

export const SetUserLogin = (userData)=>{
      SetCookie('account_type', userData.account_type);
      SetCookie('currentUser', JSON.stringify(userData));
      SetCookie('last_login_activity', JSON.stringify(new Date().getTime()));
      setAuthorization(userData.accessToken);
      localStorage.setItem('currentUserSession', userData.accessToken);
      return true;
}
