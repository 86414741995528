import React, { useEffect,useState,useContext } from 'react';
import { Container, Form, Button,Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ApiService, setAuthorization } from '../../services/ApiService';
import { SetCookie,SetUserLogin,RedirectToLogin } from '../../components/helpers/helper';
import './css/login.css';
import { useMyContext } from '../../MyContext';


const Login = () => {
  const navigate = useNavigate();
  const { userAuthenticated,setUserAuthenticated } = useMyContext();
  const { register, handleSubmit, watch, setValue, reset, resetField, trigger, clearErrors, setError, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [formSubmitted, setFormSbmt] = useState(false);

  const toggleAlert = (val) => {
    setShowAlert(val)
}

  const onSubmit = async (data = {}) => {
    if(data == null){
      return false
  }
  setFormSbmt(true)
  let payloadUrl = "auth/login"
  let method = "POST"
  let formData = {mobile:parseInt(data.mobile, 10),password:data.password}

  const res = await ApiService.fetchData(payloadUrl,method,formData)
  if (res && res.status_code) {
    if (res.status_code === "dc200") {
      const userData = res.results;
      console.log(userData);
      let auth = SetUserLogin(userData);
      if(auth){
      setUserAuthenticated(true);
      navigate('/dashboard');
      // updateData('user')
      }
    }
      else{
        toggleAlert({ show: true, type: 'danger', message: res.message });
      }
  }else{
      toggleAlert({ show: true, type: 'danger', message: res.message })
  }
  setFormSbmt(false)
  setLoading(false);
  }
  useEffect(() => {
    if(localStorage.getItem('currentUserSession')){
      navigate('/dashboard')
    }
  },[])

  return (
    <>
   
    <div className="login-dark" style={{ minHeight: '100vh', background: '#214a80', display: 'flex', alignItems: 'center', justifyContent: 'center',fontSize:"16px" }}>
  
      <Container>
     
        <form style={{ maxWidth: '400px'}} onSubmit={handleSubmit(onSubmit)}>
        <img src="/assets/img/sgpgi_logo22.png" style={{ height: '80px',backgroundColor:"white" }}/>
        <img src="assets/img/dexpert_white01.png" className="logo" alt="Logo" style={{ height: '40px' }} /><span style={{position:"absolute",marginTop:"10px"}}>&#174;</span>
          <div className="illustration"><i className="icon ion-ios-locked-outline"></i></div>
          <Form.Group>
            <Form.Control style={{fontSize:"18px"}} type="number" {...register("mobile", { required: "mobile number is required",maxLength: {value: 10,message: "enter only 10 digit mobile number" } })} placeholder="Enter Mobile Number" />
            {errors.mobile && <small className="form-text text-danger">{errors.mobile.message}</small>}
          </Form.Group>
          <Form.Group>
            <Form.Control style={{fontSize:"18px"}} type="password" {...register("password", { required: "password is required",minLength: {value: 6,message: "minimum length should be 6 characters" } })} placeholder="Password" className="form-control" />
            {errors.password && <small className="form-text text-danger">{errors.password.message}</small>}
          </Form.Group>
          <Form.Group>
            <Button  style={{fontSize:"18px"}} className="btn btn-primary btn-block" type="submit" disabled={formSubmitted}>
            {loading ? (
              <Spinner animation="border" role="status" variant="light" size='sm'>
              </Spinner>
            ) : (
              <></>
            )} Log In
            </Button>
          </Form.Group>
          <small style={{fontSize:"18px"}}>For new Account Registration </small><a href="/register" className='text-primary'>click here</a>
          <br></br>
          <a className="forgot pt-5" href="/forgot" style={{fontSize:"18px"}}>
            Forgot your password?
          </a>
        </form>
      </Container>
    </div>
    {(() => {
                if (showAlert && showAlert.show && showAlert.type == "danger") {
                    return (
                        <SweetAlert
                            danger
                            title={showAlert.message}
                            onConfirm={() => toggleAlert({ show: false, type: 'success', message: '' })}
                            confirmBtnCssClass={'btn btn-primary'}
                            onCancel={() => toggleAlert({ show: false, type: 'success', message: '' })}
                            showConfirm={true}
                            focusCancelBtn={false}
                            customClass={'i_alert fs-10'}
                            timeout={4000}
                        />
                    )
                }
            })()}
    </>
    
  );
};

export default Login;
