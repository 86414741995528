import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ApiService, setAuthorization } from '../../services/ApiService';
import { useMyContext } from '../../MyContext';
import SweetAlert from 'react-bootstrap-sweetalert';
import { log } from '@tensorflow/tfjs';

const Profile = () => {
  const { register, handleSubmit, setValue, getValues,formState: { errors } } = useForm();
  const { userProfile, setUserProfile } = useMyContext();
  const [formSubmitted, setFormSbmt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [subsDetails, setSubsDetails] = useState();

  const toggleAlert = (val) => {
    setShowAlert(val)
}

  useEffect(() => {
    setValue('fullname', userProfile.fullname);
    setValue('mobile', userProfile.mobile);
    setValue('u_age', userProfile.u_age);
  }, [setValue, userProfile]);

  const onSubmit = async (formData) => {
    try {
        setLoading(true);
        setFormSbmt(true)
        let payloadUrl = "user/updateProfile"
        let method = "POST"
        if(formData.u_age == "" || formData.u_age == null){
          formData.u_age = 0;
        }
        let setformData = {u_age:parseInt(formData.u_age),fullname:formData.fullname,password:formData.password}
        console.log(setformData);
        const res = await ApiService.fetchData(payloadUrl,method,setformData)
        toggleAlert({ show: true, type: 'success', message: res.message });
        setLoading(false);
        setFormSbmt(false);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const getSubscriptionsDetails = async()=>{
    let payloadUrl = "user/getSubscription_profile"
        let method = "GET"
        const res = await ApiService.fetchData(payloadUrl,method);
        setSubsDetails(res.results)
        console.log(res.results);
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>Profile Details</h3>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="col-xxl-8 mb-5 mb-xxl-0">
                <div className="bg-secondary-soft rounded">
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label className="form-label">Fullname</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Fullname"
                        {...register('fullname', { defaultValue: userProfile.fullname })}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Mobile Number</label>
                      <input
                        type="text" disabled={true}
                        className="form-control"
                        placeholder=""
                        aria-label="Mobile Number"
                        {...register('mobile', { defaultValue: userProfile.mobile })}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Age</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Age"
                        {...register('u_age', { defaultValue: userProfile.u_age,maxLength: {value: 3,message: "enter only 3 digit" } })}
                      />
                       {errors.u_age && <small className="form-text text-danger">{errors.u_age.message}</small>}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="inputEmail4" className="form-label">
                        Password{' '}
                        <small style={{ color: 'ActiveBorder' }}>
                          <i>Enter new password to update it</i>
                        </small>
                      </label>
                      <div className="input-group">
                        <input
                          type='password'
                          className="form-control"
                          {...register("password", { minLength: {value: 6,message: "minimum length should be 6 characters" } })}
                        />
                         {errors.password && <small className="form-text text-danger">{errors.password.message}</small>}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="button-25">
                        Update Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='col-12'>
           <hr></hr>
            <table className="table table-bordered table-sm">
        <thead>
          <tr>
            <th>SID</th>
            <th>Plan</th>
            <th>Type</th>
            <th>Credits</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Payment ID</th>
            <th>Created At</th>
            {/* Add more columns as needed */}
          </tr>
        </thead>
        {subsDetails && subsDetails.length > 0 ? (
  <tbody>
    {subsDetails.map((result, index) => (
      <tr key={index}>
        <td>{result.subscription_id}</td>
        <td>{result.plan_name}</td>
        <td>{(result.test_type_id == 1)?"Digital Pathology + TB Scans":"Individual Scans"}</td>
        <td>{result.available_credit}</td>
        <td>{result.start_date}</td>
        <td>{result.end_date}</td>
        <td>{result.pg_id}</td>
        <td>{result.created_at}</td>
        {/* Add more cells as needed */}
      </tr>
    ))}
  </tbody>
) : (
  <tbody>
    <tr>
      <td colSpan="5">check subscription details</td>
    </tr>
  </tbody>
)}
           </table>
           <button className="button-25" onClick={()=>{getSubscriptionsDetails()}}>Show Subscription Details</button>
          </div>
        </div>
      </div>
      {(() => {
                if (showAlert && showAlert.show && showAlert.type == "danger") {
                    return (
                        <SweetAlert
                            danger
                            title={showAlert.message}
                            onConfirm={() => toggleAlert({ show: false, type: 'success', message: '' })}
                            confirmBtnCssClass={'btn btn-primary'}
                            onCancel={() => toggleAlert({ show: false, type: 'success', message: '' })}
                            showConfirm={true}
                            focusCancelBtn={false}
                            customClass={'i_alert fs-10'}
                            timeout={3000}
                        />
                    )
                }else if (showAlert && showAlert.show && showAlert.type == "success") {
          return (
            <SweetAlert
              success
              title={showAlert.message}
              onConfirm={() =>
                toggleAlert({ show: false, type: "success", message: "" })
              }
              confirmBtnCssClass={"btn_15"}
              onCancel={() =>
                toggleAlert({ show: false, type: "success", message: "" })
              }
              showConfirm={true}
              focusCancelBtn={false}
              customClassName={"air_alert"}
              timeout={3000}
            />
          );
        }
            })()}
    </div>
    
  );
};

export default Profile;
