// AppRouter.jsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Login from './components/landingViews/login'
import Register from './components/landingViews/register';
import Dashboard from './layouts/dashboard';
import LandingPage from './layouts/landingpage';
import TestCompoent from './components/partial/testpage';
import TestModel from './components/partial/testmodel';
import TermsConditions from './layouts/termsConditions';
import RefundPolicy from './layouts/refund';
import ForgotPassword from './components/landingViews/forgot';

const AppRouter = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage/>} />
          <Route path="/terms" element={<TermsConditions/>} />
          <Route path="/refund" element={<RefundPolicy/>} />
          {/* <Route path="/test" element={<TestCompoent/>} />
          <Route path="/testmodel" element={<TestModel/>} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot" element={<ForgotPassword/>} />
          {/* <Route path="/dashboard/*" element={<PrivateRoute component={<Dashboard />} />} /> */}
          <Route path="/dashboard/*" element={<Dashboard />} />
        </Routes>
      </Router>
    );
  };
  
  const PrivateRoute = ({ component }) => {
    // Your authentication logic here
    const isAuthenticated = true; // Change this based on your authentication logic
  
    return isAuthenticated ? component : <Navigate to="/login" />;
  };

export default AppRouter;
