import React from 'react';

const ServicesOverview = () => {
  return (
    <div id="services" className="overview-area default-padding" style={{ backgroundImage: 'url(assets/img/robo1.png)' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h2 className="heading">Elevate Your Practice to New Heights<br /> with AI?</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="service-overview-tab-items">
          <div className="row">

            <div className="col-xl-10 offset-xl-1 text-center">
              <div className="nav nav-tabs service-overview-tab-navs" id="nav-tab" role="tablist">
                <button className="nav-link active" id="nav-id-1" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true">
                  Benefits
                </button>
                <button className="nav-link" id="nav-id-2" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false">
                  Problem solving
                </button>
              </div>
            </div>

            <div className="col-lg-8 offset-lg-2">
              <div className="tab-content service-overview-tab-content" id="nav-tabContent">
                {/* Tab Single Item */}
                <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="nav-id-1">
                  <div className="row align-center">
                    {/* Tab Inner Single */}
                    <div className="col-md-6 service-overview-single">
                      <i className="fas fa-file-alt"></i>
                      <h4><a href="#">Fast Triage</a></h4>
                      <p>
                        Quickly identify normal cases and expedite the diagnosis process.
                      </p>
                    </div>
                    {/* Tab Inner Single */}
                    {/* Tab Inner Single */}
                    <div className="col-md-6 service-overview-single">
                      <i className="fas fa-tv-retro"></i>
                      <h4><a href="#">Efficient Reading</a></h4>
                      <p>
                        Save time and prioritize cases, leading to improved overall efficiency.
                      </p>
                    </div>
                    {/* Tab Inner Single */}
                    {/* Tab Inner Single */}
                    <div className="col-md-6 service-overview-single">
                      <i className="fas fa-theater-masks"></i>
                      <h4><a href="#">Improved Accuracy </a></h4>
                      <p>
                        Boost diagnostic accuracy for chest abnormalities.
                      </p>
                    </div>
                    {/* Tab Inner Single */}
                    {/* Tab Inner Single */}
                    <div className="col-md-6 service-overview-single">
                      <i className="fas fa-shopping-basket"></i>
                      <h4><a href="#">Reduced Overlooked Cases</a></h4>
                      <p>
                        Minimize the chances of missing lung cancers and other critical conditions
                      </p>
                    </div>
                    {/* Tab Inner Single */}
                  </div>
                </div>
                {/* End Tab Single Item */}

                {/* Tab Single Item */}
                <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="nav-id-2">
                  <div className="row align-center">
                    {/* Tab Inner Single */}
                    <div className="col-md-6 service-overview-single">
                      <i className="fab fa-react"></i>
                      <h4><a href="#">Diagnostic Delays</a></h4>
                      <p>
                      DecXpert accelerates the diagnosis process, ensuring that patients receive timely care.
                      </p>
                    </div>
                    {/* Tab Inner Single */}
                    {/* Tab Inner Single */}
                    <div className="col-md-6 service-overview-single">
                      <i className="fas fa-layer-group"></i>
                      <h4><a href="#">Time-Consuming Reading</a></h4>
                      <p>
                        This will reduce the time spent on routine examinations.
                      </p>
                    </div>
                    {/* Tab Inner Single */}
                    {/* Tab Inner Single */}
                    <div className="col-md-6 service-overview-single">
                      <i className="fas fa-bug"></i>
                      <h4><a href="#">Diagnostic Accuracy</a></h4>
                      <p>
                        Achieving high diagnostic accuracy is a constant challenge.
                      </p>
                    </div>
                    {/* Tab Inner Single */}
                    {/* Tab Inner Single */}
                    <div className="col-md-6 service-overview-single">
                      <i className="fas fa-question-circle"></i>
                      <h4><a href="#">Overlooked Cases</a></h4>
                      <p>
                      DecXpert reduces the chances of overlooking, ensuring that no condition goes undiagnosed.
                      </p>
                    </div>
                    {/* Tab Inner Single */}
                  </div>
                </div>
                {/* End Tab Single Item */}

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesOverview;
