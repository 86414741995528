import React from 'react';
import { Link } from 'react-router-dom';

const AboutSection = () => {
  return (
    <div id="about" className="choose-us-style-two-area default-padding overflow-hidden bg-gray">
      <div className="container">
        <div className="row align-center">
          <div className="col-lg-5">
            <div className="choose-us-fun-fact text-center" style={{ backgroundImage: 'url(assets/img/banner-3.webp)' }}>
              <div className="fun-fact">
                <div className="counter">
                  <div className="timer" data-to="106" data-speed="2000">205</div>
                  <div className="operator">K</div>
                </div>
                <span className="medium">Xray Scan Processed</span>
              </div>
              <div className="fun-fact">
                <div className="counter">
                  <div className="timer" data-to="90" data-speed="2000">90</div>
                  <div className="operator">%</div>
                </div>
                <div className="user-ratings">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half"></i>
                </div>
                <span className="medium">(4,655) Positive Rating</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1">
            <div className="choose-us-style-two">
              <h4 className="sub-heading theme">Artificial Intelligencer</h4>
              <h2 className="heading">Get Benefits of using the latest Artificial <strong>Intelligence</strong>.</h2>
              <p>
                Experience the cutting-edge in healthcare with DecXpert, harnessing the latest Artificial Intelligence technologies. Just input your chest X-ray images, and let DecXpert do the rest.
              </p>
              <ul>
                <li>Chest X-ray</li>
                <li>Tuberculosis Screening</li>
                <li>Comprehensive Report </li>
              </ul>
              {/* <Link to="/register" className="btn circle mt-35 btn-sm btn-border light secondary">Create your account</Link> */}
              <a href="/register" className="btn circle mt-35 btn-sm btn-border light secondary">Create your account</a>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
