import React,{useEffect} from 'react';
import { RedirectToLogin } from '../../components/helpers/helper';
import { useMyContext } from '../../MyContext';
import { ApiService, setAuthorization } from '../../services/ApiService';
import userImage from '../../assets/img/user.png';

const Topbar = () => {
  const { userProfile, setUserProfile } = useMyContext();



  const getUserProfile = async () => {
    let payloadUrl = "user/getProfile"
  let method = "GET"
  let formData = {}
  let data = {};

  const res = await ApiService.fetchData(payloadUrl,method,formData)
  console.log(res);
  if (res && res.status_code) {
    let result = res.results[0];
    if(result){
      data.fullname = result.fullname;
      data.u_age = result.u_age;
      data.mobile = result.mobile;
      setUserProfile(data);
    }

  }

  }

  useEffect(() => {
    getUserProfile();
  },[])

  return (
    <>
    <nav className= {`bg-white topbar mb-4 static-top shadow`}>
    <div class="container">
    <img src="../assets/img/sgpgi_logo22.png" className="logo" alt="Logo" style={{ height: '132px',float:"left" }} />
  <div class="d-flex flex-wrap align-items-center flex-row-reverse">

    <div class="dropdown text-end mt-3 ml-25">
      <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <img src={userImage} alt="mdo" width="40" height="40" class="rounded-circle"/>
        <small class="ml-5 text-sm">{userProfile.fullname}</small>
      </a>
      <ul class="dropdown-menu text-small">
        <li><a class="dropdown-item" href="/dashboard/profile">Profile</a></li>
        <li><hr class="dropdown-divider"/></li>
        <li><a class="dropdown-item" href="#" onClick={RedirectToLogin}>Logout</a></li>
      </ul>
    </div>
  </div>
</div>

    </nav>
    </>
  );
}

export default Topbar;
