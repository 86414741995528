import React from 'react';

const ProcessStyleTwo = () => {
  return (
    <div id="process" className="process-style-two-area default-padding bg-gray bg-fixed overflow-hidden" style={{ backgroundImage: 'url(assets/img/banner-3.jpg)' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="heading-left">
              <h5 className="sub-heading theme">How it works</h5>
              <h2 className="heading">DecXpert uses AI and Deep Learning Technologies. </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="process-style-two-items">
          <div className="row">
            {/* Single Item */}
            <div className="col-lg-4">
              <div className="process-style-two active">
                <h4>Detect Location</h4>
                <p>
                  Our system generates heatmaps and contour maps, pinpointing the exact location of detected lesions. This information aids in precise diagnosis.
                </p>
                <ul>
                  <li>Precision</li>
                  <li>Heatmaps</li>
                  <li>Contour Maps</li>
                </ul>
              </div>
            </div>
            {/* End Single Item */}
            {/* Single Item */}
            <div className="col-lg-4">
              <div className="process-style-two">
                <h4>Abnormality Score </h4>
                <p>
                DecXpert provides an abnormality score that quantifies the likelihood of lesion presence, making it easier to prioritize cases.
                </p>
                <ul>
                  <li>Prioritization</li>
                  <li>Quantification</li>
                  <li>Streamlined</li>
                </ul>
              </div>
            </div>
            {/* End Single Item */}
            {/* Single Item */}
            <div className="col-lg-4">
              <div className="process-style-two">
                <h4>AI Report</h4>
                <p>
                  Receive a comprehensive AI case report, the analysis results, overview of the patient's condition allowing for a focused approach to patient care.
                </p>
                <ul>
                  <li>Comprehensive</li>
                  <li>Insights</li>
                  <li>Informed Decision-making</li>
                </ul>
              </div>
            </div>
            {/* End Single Item */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessStyleTwo;
