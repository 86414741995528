import React, { useState, useEffect } from 'react';
import Header_OnlyLogo from "../components/landingViews/header_onlyLogo.jsx";
import Preloader from "../components/landingViews/preloader.jsx";
import BannerStyleFour from "../components/landingViews/banner.jsx";
import ProcessStyleTwo from "../components/landingViews/process.jsx";
import ServicesOverview from "../components/landingViews/service.jsx";
import AboutSection from "../components/landingViews/about.jsx";
import PricingSection from "../components/landingViews/pricing.jsx";
import FAQSection from "../components/landingViews/faq.jsx";
import TeamGallery from "../components/landingViews/team.jsx";
import Footer from "../components/landingViews/footer.jsx";


const TermsConditions = () => {
    const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowPreloader(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);


    return (
       <>
      {showPreloader ? (
        <Preloader />
      ) : (
        <div className="bg-fixed dark-layout text-light" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/banner-2.jpg)` }}>
           <Header_OnlyLogo />
            <div classname="row">
        <div classname="container">
        <div className='col-lg-12 col-md-12'>
            <div className='col-lg-8 ml-200 pt-150 pb-50'>

        <center><h1>Privacy Policy</h1></center>
        <>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        We acknowledge the significance of your privacy and value the trust you
        place in us regarding the careful and responsible collection and
        processing of your information.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>This privacy policy (</span>
    <span className="c4">
      <strong>“Privacy Policy”</strong>
    </span>
    <span>
      ) is in accordance with the Indian Information Technology Act, 2000 (
    </span>
    <span className="c4">
      <strong>“IT Act”</strong>
    </span>
    <span>
      ) and the Information Technology (Reasonable Security Practices and
      Procedures and Sensitive Personal Data or Information) Rules, 2011&nbsp;
    </span>
    <span className="c4">
      <strong>(“IT Rules”)</strong>
    </span>
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        .
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>
      The Privacy Policy serves as a legal agreement between you (referred to as
      “You” or “Your” or “User”), the user of the Website&nbsp;
    </span>
    <span className="c4">
      <strong>https://the-decxpert.com/</strong>
    </span>{" "}
    <span className="c4">
      <strong>(“Website”)</strong>
    </span>
    <span>
      , the mobile application called DecXpert, collectively referred to as the
      (
    </span>
    <span className="c4">
      <strong>“Platform”)</strong>
    </span>
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        , and Dectrocel Healthcare and Research Pvt. Ltd. &nbsp;(Referred to as
        “We” or “Us” or “Company”), which is incorporated under the laws of
        India with its registered office located at S-II 212 C Udayan II,
        Eldeco, Lucknow, Uttar Pradesh – 226025.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    id="h.gjdgxs"
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        This statement outlines the regulations regarding:
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        The gathering and utilization of Personal Information and Sensitive
        Personal Data or Information provided by you, and
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        The retention and handling of Personal Information and Sensitive
        Personal Data or Information provided by you while using our website
        and/or the Application. Please note that this Privacy Policy, subject to
        occasional updates, should be read in conjunction with the T&amp;C
        accessible on the Website and/or the Application. We recommend checking
        the Privacy Policy periodically for any changes that may affect you.
      </span>
    </span>
    <br />
    &nbsp;
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        BY WILLINGLY SHARING INFORMATION WITH US, YOU ARE AGREEING TO OUR USAGE
        OF IT BASED ON THIS PRIVACY POLICY.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>DEFINITIONS</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        For the purposes of this Privacy Policy and the T&amp;C, certain terms
        with capitalization have the following meanings:
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Personal Information refers to any information pertaining to an
        individual that, either directly or indirectly, alone or in combination
        with other available or potentially available information, can identify
        that individual and is not publicly accessible. Examples include a
        person’s name, age, phone number, address, date of birth, voice,
        opinions about others, national insurance number, driving license
        number, permanent account number, Aadhar Card details, and similar
        information.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Sensitive Personal Data or Information encompasses personal information
        that relates to any of the following aspects of an individual:
      </span>
    </span>
  </p>
  <ul
    className="c3 lst-kix_list_1-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -passwords;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -financial information such as bank account or credit card or debit
            card or other payment instrument details;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -physical, physiological and mental health condition;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -sexual orientation;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -medical records and history;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -biometric information;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -any detail relating to the above clauses as provided to body
            corporate for providing service; and
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -any of the information received under above clauses by body
            corporate for processing, stored or processed under lawful contract
            or otherwise.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        as specified in the IT Rules.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        User Information collectively refers to your Personal Information,
        Sensitive Personal Data or Information, and any other information
        obtained from you.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>OUR DATA COLLECTION PRACTICES</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        We only use the information provided by you for the specific purposes
        outlined in this Privacy Policy.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Our website and/or the Application can be explored without revealing any
        personal information or your identity. However, certain areas or
        features of the Website and/or the Application may require registration
        or disclosure of your identity, as well as other Personal or Sensitive
        Personal Information about you.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        &nbsp;During your use of our website and/or the Application, we collect
        two types of information from you:
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Information you provide directly to us:
      </span>
    </span>
  </p>
  <ul
    className="c3 lst-kix_list_2-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -to facilitate the provision of our services;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -through interview, surveys and feedback submissions;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -when you communicate with us via email or SMS;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -personally identifiable details from you including, but is not
            limited to, your complete name (including first and last name),
            email address, mobile phone number, contact information, username
            and the password you chose for your website and/or the Application
            account setup; and
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -additionally, we may collect your date of birth, age, gender,
            residential address, postal code etc.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Information that is automatically collected:
      </span>
    </span>
  </p>
  <ul
    className="c3 lst-kix_list_3-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -during the registration process on our website, we may gather
            specific information about the pages you accessed or visited on the
            Website;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -the links you clicked on;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -the frequency of your visits to particular pages on the Website
            i.e., logs related information automatically reported by your
            browser each time you access a web page.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -Internet Protocol (IP) address;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -location (via Global Positioning System);
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -browser type;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -referring/exit pages;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -uniform resource locator;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -number of clicks;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -domain names;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -landing pages;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -click paths;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -feature usage, allied data; and
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -any other relevant browsing information.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>UTILIZATION OF INFORMATION/DATA</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        The manner in which we utilize this personal information:
      </span>
    </span>
  </p>
  <ul
    className="c3 lst-kix_list_4-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -examine the usage of and enhance the services.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -perform investigative research and analysis to generate reports and
            statistics pertaining to associated medical conditions.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -offer the Website and/or the Application and its services and
            address your inquiries.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -reach out to you with pertinent details and promotions.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -adhere to legal requirements or protect our legal interests.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -ensure security and prevent fraudulent activities.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -monitor, enhance, and manage our website and/or the Application.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -analyse the usage of the Website and/or the Application, identify
            service or technical issues, and maintain security.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -retain information to assist you in accessing the Website and/or
            the Application efficiently.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -track aggregate metrics like the total number of viewers, visitors,
            traffic, and demographic patterns.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -verify your identity to ensure eligibility for using the Website
            and/or the Application.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -contact you to ensure user satisfaction regarding your use of the
            Website and/or the Application.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -provide you with requested information, as long as you have given
            consent to be contacted for such purposes.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -fulfil our obligations arising from contracts between you and us,
            as well as between us and third-party service providers.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -notify you about any changes on our website and/or the Application.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -ensure the functioning of any accessed or subscribed
            features/services, specifically tailored to deliver them to you.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -address any queries or information requests made by you.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -generate summarized or anonymous data i.e., modify Personal
            Information by removing identifying elements, and utilize the
            modified information publicly to improve our service or product.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -utilize anonymized SMS and emails related to Website and/or the
            Application usage to enhance our services.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>ACCESSING YOUR INFORMATION</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        You can access your information, such as your name, address, profile
        details which can be done through the Account section of the Website
        and/or the Application. You can also retrieve your symptom assessment,
        interviews, health status, and medical information.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Declining to submit Identifiable Information: You have the option to
        choose not to provide identifiable information through the Website
        and/or the Application. However, this may result in restricted access to
        certain features or parts of the Website and/or the Application.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Updating and Correcting Account Information: If you are a registered
        user, you can update or correct your account information and email
        preferences anytime by logging into your account.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>
       &nbsp;Ensuring Accuracy of Information: If you believe that any of the
      information we hold about you is inaccurate, you can notify us at&nbsp;
    </span>
    <span style={{ color: "rgb(5,99,193)" }}>
      <span className="c8" style={{ textDecorationSkipInk: "none" }}>
        <u>dectrocelhealthcare@gmail.com</u>
      </span>
    </span>
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        . It is your responsibility to ensure that the information you provide
        remains accurate and up to date.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Data Retention: The information collected by us will not be kept longer
        than necessary for its lawful use or as required by applicable laws.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Your Legal Rights: Depending on the local laws (e.g., if you are a
        citizen or resident of India), you may have certain legal rights
        regarding your information. These rights may include requesting access
        to and a copy of your information, updating or correcting outdated or
        incorrect information, deleting specific information we hold about you,
        and restricting the processing and disclosure of certain information.
        You may also have the right to withdraw your consent to the processing
        of your information for certain purposes. We will respond to your
        requests within the time limits specified by applicable law and may
        require additional information to verify your identity.
      </span>
    </span>
    <br />
    &nbsp;
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>PROMOTIONAL COMMUNICATIONS</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        &nbsp;In accordance with the relevant legal obligations, we may send you
        promotional communications about our Services or third-party services
        that we believe may interest you. However, you have the right to request
        that we stop sending such communications at any time. To do so, please
        contact us using the contact details provided in the “Contact
        Information” section below. Additionally, in our promotional email
        messages, you can choose to unsubscribe by following the instructions
        provided at the bottom of the emails. It is important to note that even
        if you opt out, we may still use and disclose certain information as
        permitted by our policy or as required by law. This includes
        transactional emails that are necessary for confirming your requests or
        providing updates on our policy or other terms, which cannot be opted
        out of.
      </span>
    </span>
    <br />
    &nbsp;
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>EXTERNAL/THIRD PARTY WEBSITES</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        The Services we provide might include connections to external websites
        and additional functionalities offered by third parties. If you decide
        to access these websites and utilize their services, please understand
        that we are not accountable for the content they provide or their
        approach to privacy. The gathering, utilization, and sharing of your
        information will be governed by the privacy policies of these
        third-party websites and not by our own Policy. We strongly recommend
        that you review the privacy policies of these third parties.
      </span>
    </span>
    <br />
    &nbsp;
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>GLOBAL USERS</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        We, along with our third-party service providers, handle and process
        your information in India and various other locations. As a result, your
        data may be maintained, processed, and stored in jurisdictions with
        distinct data protection laws compared to your country of residence. If
        your information is transferred in this manner, rest assured that we
        adhere to the relevant legal requirements concerning cross-border
        information transfers. By using the Services, you consent to and
        acknowledge these data transfers. We will take necessary steps to ensure
        that your information is treated securely and in accordance with this
        Privacy Policy.
      </span>
    </span>
    <br />
    &nbsp;
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>FOREIGN JURISDICTION</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        We do not guarantee that the content on the Website and/or the
        Application is suitable for use or access outside the Republic of India.
        If you choose to use or access the Website and/or the Application from a
        location outside the Republic of India, you assume the risk and are
        responsible for following the laws of that jurisdiction.
      </span>
    </span>
    <br />
    &nbsp;
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>INFORMATION DISCLOSURE</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        With the exception of what is stated in the Privacy Policy, we will not
        reveal or transfer your Sensitive Personal Information to any third
        party without your prior consent, except in the following cases:
      </span>
    </span>
  </p>
  <ul
    className="c3 lst-kix_list_5-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -When it is necessary for the performance of a contract between the
            Company and you, and you have given us permission to share your
            Sensitive Personal Information.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -As part of a corporate reorganization, merger, acquisition, or
            business sale.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -When required by applicable laws or through a court order.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Regarding the disclosure of information to third parties, we will share
        your Personal Information in the following manner:
      </span>
    </span>
  </p>
  <ul
    className="c3 lst-kix_list_6-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -When you request Services provided on the Website and/or the
            Application, or through third parties, we will share your Personal
            Information with the necessary parties involved in providing the
            Services.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -We may share your Personal Information with third parties
            authorized by you, and in such cases, the use of your information by
            those third parties will be bound by this Privacy Policy or their
            respective privacy policies.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -We may disclose your information to any of our related or group
            companies, including subsidiaries and ultimate holding company, as
            applicable.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -If we sell or purchase any business or assets, we may disclose your
            Personal Information, with your prior consent, to the prospective
            seller or buyer. User, email, and visitor information is typically
            one of the assets transferred in such transactions. We may also
            transfer or assign this information during corporate divestitures,
            mergers, or dissolution.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -We may disclose your Personal Information to third-party service
            providers solely for the purpose of them providing services to us.
            We will take reasonable precautions to ensure that these service
            providers are obligated to maintain the confidentiality of your
            information.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -We may disclose your Personal Information if we are legally
            obligated to do so in order to comply with any legal obligation or
            to protect our rights, property, or safety, as well as those of our
            users or other third parties. This may involve sharing information
            with other companies and organizations for fraud protection and
            credit risk reduction purposes.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -We may disclose your information to governmental and other
            statutory bodies that have appropriate authorization to access it
            for specific legal purposes, without requiring your prior consent.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -We ensure that all third parties with whom we share any Sensitive
            Personal Information adhere to the same level of data protection as
            specified under the IT Rules that we have adopted.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <p
    className="c1 c7"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      height: "11pt",
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>COOKIES</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        When you engage with the Website, our goal is to provide you with a
        convenient and meaningful experience. Upon visiting our Website, our
        server will send a small text file, known as a cookie, to your computer.
        This cookie contains information related to the Website you are visiting
        and may be stored on your computer or mobile device. Its purpose is to
        enable the Website to remember your preferences or actions over a
        certain period of time, and it can also inform us whether you have
        previously visited the Website. If you click on a link leading to a
        third-party website, that third party may also send cookies to your
        device. Third party data protection practices are governed by their own
        privacy policies, which ensure the same level or better data protection
        as we adhere to. Cookies assist us in enhancing our website and
        providing a more personalized and improved Services. They can also
        ensure that the online advertisements you encounter are more relevant to
        your interests.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        The use of cookies by our partners, affiliates, advertisers, or service
        providers is not covered by our Privacy Policy.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        You have the option to refuse the acceptance of cookies by adjusting
        your browser settings. However, this may limit your access to certain
        parts of our website or Application. If you have not changed your
        browser settings to refuse cookies, our system may issue cookies when
        you log in.
      </span>
    </span>
    <br />
    &nbsp;
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>DATA STORAGE AND PROTECTION</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        We make every effort to securely store all the information we collect in
        databases under our control. We take reasonable measures to ensure that
        appropriate physical, technical, and managerial safeguards are in place
        to protect your Personal Information from unauthorized access,
        alteration, transmission, and deletion.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        &nbsp;We employ commercially reasonable safeguards to maintain the
        integrity and security of your information, safeguarding it against
        loss, theft, unauthorized access, disclosure, reproduction, use, or
        modification.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        By using the Website and/or the Application, you acknowledge and accept
        the inherent security risks associated with transmitting data over the
        internet and the world wide web, as complete security cannot always be
        guaranteed. Therefore, your use of the Website and/or the Application is
        at your own risk.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        We are not liable for any disclosure of information resulting from
        transmission errors, unauthorized access by third parties, acts of third
        parties beyond our control, or omissions. You agree not to hold us
        responsible for any security breaches.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        If we become aware of any breach of your information’s security, we will
        promptly notify you and take appropriate action to address the breach to
        the best of our ability.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        &nbsp;You agree to immediately report any incidents involving suspected
        or actual unauthorized access, disclosure, alteration, loss, damage, or
        destruction of data to us.
      </span>
    </span>
    <br />
    &nbsp;
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>GRIEVANCE OFFICER</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        In accordance with the Information Technology Act, 2000, the contact
        details of the Grievance Officer at our company are provided below. You
        may:
      </span>
    </span>
  </p>
  <ul
    className="c3 lst-kix_list_7-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -request access to your Sensitive Personal Data or Information,
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            -report any grievances related to your Sensitive Personal Data or
            Information, or
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span>
          -report any security breach related to your Sensitive Personal Data or
          Information to the&nbsp;
        </span>
        <a
          className="c6"
          style={{ color: "inherit", textDecoration: "inherit" }}
          href="mailto:dectrocelhealthcare@gmail.com"
        >
          <span style={{ color: "rgb(5,99,193)" }}>
            <span className="c8" style={{ textDecorationSkipInk: "none" }}>
              <u>dectrocelhealthcare@gmail.com</u>
            </span>
          </span>
        </a>
      </p>
    </li>
  </ul>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>CONTACT</strong>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>
      For any inquiries regarding the collection, processing, usage, or
      disclosure of your information, please email us at&nbsp;
    </span>
    <span style={{ color: "rgb(5,99,193)" }}>
      <span className="c8" style={{ textDecorationSkipInk: "none" }}>
        <u>dectrocelhealthcare@gmail.com</u>
      </span>
    </span>
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        .
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        You may also withdraw your consent for us to use and process your
        information by sending a written request to the aforementioned email id.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        We have a no refund policy.
      </span>
    </span>
  </p>
  <p
    className="c1 c7"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      height: "11pt",
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      className="c0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        fontStyle: "normal",
        fontWeight: 400,
        textDecoration: "none",
        verticalAlign: "baseline"
      }}
    />
  </p>
  <p
    className="c1 c7"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      height: "11pt",
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      className="c0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        fontStyle: "normal",
        fontWeight: 400,
        textDecoration: "none",
        verticalAlign: "baseline"
      }}
    />
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>Welcome to the website&nbsp;</span>
    <span className="c4">
      <strong>https://the-decxpert.com/</strong>
    </span>
    <span>&nbsp;(</span>
    <span className="c4">
      <strong>“Website”</strong>
    </span>
    <span>&nbsp;or&nbsp;</span>
    <span className="c4">
      <strong>“Platform”</strong>
    </span>
    <span>
      ). The Website is owned and operated by Dectrocel Healthcare and Research
      Pvt. Ltd. (
    </span>
    <span className="c4">
      <strong>“Company”</strong>
    </span>
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        ), a legally incorporated entity under the provisions of the Companies
        Act, 2013, with its registered office located at S-II 212 C Udayan II,
        Eldeco, Lucknow, Uttar Pradesh – 226025.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>DecXpert&nbsp;</span>
    <span className="c4">
      <strong>(“Application”)</strong>
    </span>
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        &nbsp;is also available for free download on your mobile device via our
        Website / IOS / Play Store / APK Mirror.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>These terms and conditions (</span>
    <span className="c4">
      <strong>“T&amp;C”</strong>
    </span>
    <span>
      ), along with the Privacy Policy and any other policies of the Company
      (collectively referred to as the&nbsp;
    </span>
    <span className="c4">
      <strong>“Policies”</strong>
    </span>
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        ), apply to all individuals accessing or using the Website, Application
        and/or any services offered. These Policies may be revised and updated
        periodically. All products, software available on the Website and
        Application are governed by these T&amp;C. We reserve the right to
        modify and update the T&amp;C by publishing the changes on the Website
        and Application, without any prior obligation to notify users. We
        recommend that you review the T&amp;C regularly for any updates or
        changes that may affect you.
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>
      If you have any questions regarding the T&amp;C, or if you wish to provide
      feedback or raise complaints about the Website or the Application, please
      contact us at&nbsp;
    </span>
    <a
      className="c6"
      style={{ color: "inherit", textDecoration: "inherit" }}
      href="mailto:dectrocelhealthcare@gmail.com"
    >
      <span style={{ color: "rgb(5,99,193)" }}>
        <span className="c8" style={{ textDecorationSkipInk: "none" }}>
          <u>dectrocelhealthcare@gmail.com</u>
        </span>
      </span>
    </a>
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        .
      </span>
    </span>
  </p>
  <ol
    className="c3 lst-kix_list_8-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_8-0 0",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_8-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>GENERAL</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_9-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            By accessing or using the Website or Application, you acknowledge
            and agree, without any limitations or exceptions, to be bound by
            these T&amp;C and Policies, regardless of whether you have read
            them. Your access, browsing, or use of the Website and/or
            Application including any of the products and software (whether
            through a computer or a mobile phone device, illustratively)
            signifies your complete acceptance of all the terms and conditions
            outlined in these T&amp;C. Therefore, please carefully review these
            terms before proceeding.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_10-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            In the context of these T&amp;C, the terms “User” or “You” (or any
            variation thereof) refer to any individual or legal entity
            (including any legal heirs, administrators, or successors) who has
            agreed to become a user of the Website and/or Application. If you
            are accepting these T&amp;C and using the Website and/or Application
            on behalf of a company, organization, government, or other legal
            entity, you represent and warrant that you have the authority to do
            so.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_11-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            The terms “Dectrocel Healthcare and Research Pvt. Ltd.,” “We,” “us,”
            “our,” “DecXpert” or “Company” refer to Dectrocel Healthcare and
            Research Pvt. Ltd., including its subsidiaries, licensees,
            affiliates, directors, officers, and employees.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_12-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            If you do not agree with these T&amp;C or are unwilling to be bound
            by them, please refrain from using the Website and the Application
            or any information or services provided through them.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_13-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            By using the Website and/or Application, you agree to:
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_14-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            utilize it solely for purposes permitted by the T&amp;C; and
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_15-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            comply with all applicable laws, regulations, and generally accepted
            practices or guidelines.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ol
    className="c3 lst-kix_list_16-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_16-0 1",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={2}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_16-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>SERVICES</strong>
        </span>
      </p>
    </li>
  </ol>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        The DecXpert Platform is a platform to interpret digital X- ray images
        for 18 chest conditions with AI/ML and download Assessment Reports for
        clinical assistance. Subject to the compliance with the T&amp;C, we
        hereby grant a limited, non-exclusive, non-transferable, royalty free
        license to use the Website and the Application for the purposes of
        availing the services from us, which include without limitation the
        following:
      </span>
    </span>
  </p>
  <ul
    className="c3 lst-kix_list_17-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            identifying, evaluating symptoms and related services;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            keeping record of personalized health details of any user;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            monitoring and tracking symptoms of the user;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            access to medical database supported by DecXpert exceptional
            artificial intelligence technology; and
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            interface for resolution of any grievance, query of the user.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span>These are collectively referred to as the&nbsp;</span>
    <span className="c4">
      <strong>“Services”</strong>
    </span>
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        . We reserve the right to modify the Services at any time at our sole
        discretion.
      </span>
    </span>
  </p>
  <ol
    className="c3 lst-kix_list_18-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_18-0 2",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={3}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_18-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>ELIGIBILITY</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_19-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            To be eligible for accessing the Website and/or Application,
            utilizing any of its services, the following conditions must be met:
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_20-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            you must be at least 18 (eighteen) years old;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            if you are a minor, we assume that you are accessing the Website
            and/or Application under the supervision of a lawful guardian;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            you must not be a person prohibited by applicable laws from
            receiving the Services; and
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            you must possess the legal capacity to enter into a binding
            contract.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        We reserve the right to decline access to the Website and/or Application
        for new users. Additionally, any user who has been suspended or removed
        by us for any reason whatsoever shall not have the entitlement to avail
        the Services.
      </span>
    </span>
  </p>
  <ol
    className="c3 lst-kix_list_21-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_21-0 3",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={4}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_21-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>REGISTRATION/SIGNING UP/ACCOUNT CREATION</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_22-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span>
          You have the option to access the Website and/or Application without
          registering. However, to utilize various Services, it is necessary to
          create an account (
        </span>
        <span className="c4">
          <strong>“Account”</strong>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            ) by providing the required information. We reserve the right, at
            our sole discretion, to reject your Account application or cancel an
            existing Account for any reason.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_23-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span>
          You are responsible for all activities and content associated with
          your Account, including photos, images, videos, graphics, written
          content, audio files, code, information, or data uploaded, collected,
          generated, stored, displayed, distributed, transmitted, or exhibited (
        </span>
        <span className="c4">
          <strong>“User Content”</strong>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            ).
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_24-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            By providing information, you represent and warrant that you have
            the necessary rights to such information and that it does not
            infringe upon the proprietary, intellectual property, or other
            rights of third parties, nor does it contain any defamatory,
            tortious, or unlawful information.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_25-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            A breach or violation of any term in the T&amp;C, as determined
            solely by us, may result in the immediate suspension or termination
            of Services available through your Account.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_26-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You are responsible for maintaining the confidentiality of your
            Account information and for all activities conducted under your
            Account. You agree to keep your login credentials secure and
            confidential at all times. Additionally, you agree to promptly
            change your login credentials and notify us immediately in the event
            of any actual or suspected unauthorized use of your Account.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_27-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            We cannot and will not be held liable for any loss or damage arising
            from your failure to comply with the provisions outlined in this
            Clause. You may be held accountable for losses incurred by us or any
            other user or visitor to the Website and/or Application due to
            authorized or unauthorized use of your Account resulting from your
            failure to keep your Account information secure and confidential.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ol
    className="c3 lst-kix_list_28-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_28-0 4",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={5}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_28-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>USER INFORMATION</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_29-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You shall not host, display, upload, modify, publish, transmit,
            store, update or share any information that belongs to another
            person and to which you do not have right.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_30-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            If you provide any information that is untrue, inaccurate,
            misleading, not existing or incomplete or we have reasonable grounds
            to believe that such information is untrue, inaccurate, misleading,
            not current or incomplete, or not in accordance with the T&amp;C, we
            reserve the right to indefinitely suspend or terminate or block your
            use or access to the Website and/or Application in any manner
            whatsoever.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_31-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            Should any other user or person act upon such untrue, inaccurate,
            not existing or incomplete information provided or verified by you,
            the Company, and its personnel shall not be liable for any damages
            or losses, direct, indirect, immediate or remote, interests or
            claims resulting from such information to you or to any third party.
            You hereby agree and undertake to indemnify and to hold harmless the
            Company, and its personnel in accordance with the indemnity clause
            contained in these T&amp;C.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ol
    className="c3 lst-kix_list_32-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_32-0 5",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={6}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_32-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>USE OF THE WEBSITE AND/OR APPLICATION</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_33-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You agree that you shall not copy, reproduce, sell, redistribute,
            publish, enter into a database, display, perform, modify, alter,
            transmit, license, create derivatives from, transfer or in any way
            exploit any part of any information, content, materials, services
            available from or through the Website and/or Application, except
            that you may download such content for your own personal, internal
            and non-commercial use.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_34-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You agree that you shall not use the Website and/or Application in
            any manner or engage in any activity that may damage, disable or
            impair or adversely affect the use of the Website and/or
            Application, interfere with any other user’s use, legal rights, or
            enjoyment of the Website and/or Application. Further, you agree not
            to remove any text, copyright or other proprietary notices contained
            in the content downloaded from the Website and/or Application.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_35-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You acknowledge and agree that except for the Services explicitly
            provided by the Company, Company does not provide, control or
            endorse any third-party information, products or services in any
            manner whatsoever, even if such third-party information, products or
            services are displayed/featured on the Website.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_36-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You shall notify Company of any material change in your situation
            and/or profile and Company would rely on the most recent information
            provided by you.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_37-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            We reserve the right to remove, delete or edit any reviews,
            comments, posts and any other content which you may post on the
            Website and/or Application, that in our sole discretion is illegal,
            obscene, abusive, defamatory, threatening, infringing of
            intellectual property rights and/or violates these T&amp;C.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_38-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            Further, you undertake not to:
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_39-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            engage in activities that harm the reputation, mistreat, harass,
            threaten, or infringe upon the legal rights of others.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            transmit, publish, display, copy, distribute, translate, or
            reproduce any content on the Platform and/or Application without
            obtaining proper authorization from the Company.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            upload or distribute files that contain protected software or other
            materials governed by intellectual property laws, unless you have
            the necessary rights or permissions.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            upload or distribute files that contain viruses, corrupted data, or
            any similar software or programs that may harm the functioning of
            the Website and/or Application or other users' computers.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            disrupt or interfere with the accessibility of the Website and/or
            Application, including the connected servers and networks.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            illegitimately attempt to access any part or feature of the Website
            and/or Application, other systems or networks linked to the Website
            and/or Application, the Company's servers, or engage in hacking or
            password mining.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            test or probe the vulnerability of the Website and/or Application or
            any connected network or breach the security or authentication
            measures of the Website and/or Application or any connected network.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            refrain from reverse engineering, tracing, or attempting to trace
            information about other users or visitors of the Website and/or
            Application, exploit the Website and/or Application or its provided
            information, with the intent to reveal any information, including
            personal identification, that does not belong to you.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            disrupt, harm, or interfere with the security of the Website, system
            resources, accounts, passwords, servers, or networks accessible
            through the Website or any affiliated or linked sites.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            gather or store data about other users in connection with prohibited
            conduct and activities.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            use the Website, materials, or content for unlawful purposes or
            activities prohibited by these T&amp;C or solicit the performance of
            illegal activities or actions that infringe upon the rights of the
            Company or third parties.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            violate any applicable laws or regulations, either within or outside
            India, or violate anyone's right to privacy or personality.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            breach the T&amp;C provided herein or elsewhere.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ol
    className="c3 lst-kix_list_40-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_40-0 6",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={7}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_40-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_41-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            To the maximum extent allowed by applicable law, the Company and its
            third-party partners hereby renounce all warranties or guarantees –
            whether mandated by statute, expressly stated, or implied –
            including, but not limited to, implied warranties of
            non-infringement of proprietary rights, and freedom from computer
            viruses or other harmful code. We cannot guarantee that any
            information provided by us is accurate, complete, or useful, or that
            our Services will be operational, error-free, secure, or safe, or
            that our Services will operate without disruptions, delays, or
            imperfections. We have no control over how or when our users utilize
            our Services or the features, Services, and interfaces we offer. We
            are not liable for, and have no obligation to control, the actions
            or information (including content) of our users or other third
            parties.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_42-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            No advice or information, whether related to any Product, Service or
            otherwise, whether oral or written, obtained by you from the Website
            and/or Application will create any warranty or guarantee other than
            those expressly stated herein. The content on this Website and/or
            Application does not constitute, and is not intended to constitute,
            advice of any kind, and you are encouraged to conduct independent
            analysis before using the Products, Services.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_43-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            For the purposes of this Disclaimer, you expressly acknowledge that
            the term "Company" includes Company’s officers, directors,
            employees, affiliates, and subsidiaries. You explicitly agree that
            the use of the Services is solely at your own risk.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_44-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span>
          The Website and/or Application, all information provided through the
          Website and/or Application, and all materials, including but not
          limited to images, text, illustrations, designs, icons, photographs,
          programs, music clips or downloads, and video clips
          (collectively,&nbsp;
        </span>
        <span className="c4">
          <strong>“Content”</strong>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            ), products, and Services made available to you through the Website
            and/or Application are provided on an “AS IS” best effort basis
            without any representation or warranties, express or implied, unless
            otherwise specified in writing.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_45-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You expressly agree that the use of the Services is at your sole
            risk. The Services and any data, information, third-party software,
            reference sites, Services, or software made available in conjunction
            with or through the Services are provided on an "as is" and "as
            available," "with all faults" basis, and without warranties or
            representations of any kind, either express or implied. We do not
            authorize anyone to make any warranty on our behalf, and you should
            not rely on any such statement.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_46-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            To the fullest extent permitted under applicable law, the Company
            shall not be liable for any indirect, incidental, special,
            consequential, or exemplary damages, including but not limited to,
            damages for loss of profits, goodwill, use, data, or other
            intangible losses arising out of or in connection with the Website
            and/or Application, its Services, Products, or these T&amp;C, even
            if we have been advised of the possibility of such damages.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ol
    className="c3 lst-kix_list_47-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_47-0 7",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={8}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_47-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>INDEMNITY</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_48-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You shall fully indemnify and hold harmless the Company, its
            officers, directors, agents, employees, and associate companies,
            from any and all costs, losses, claims, demands, damages and
            liabilities, actions including costs and reasonable attorneys’ fees,
            made by any third party and/or penalty imposed, due to and/or
            arising out of:
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_49-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            your breach of these T&amp;C;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            violation of any applicable law and/or the rights of a third party
            including but not limited to intellectual property rights, violation
            of rights of privacy or publicity or other rights;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            any obscene or indecent postings, and on-line defamation and;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            loss of Service by other subscribers.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        This Clause shall survive the expiry or termination of T&amp;C.
      </span>
    </span>
  </p>
  <ol
    className="c3 lst-kix_list_50-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_50-0 8",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={9}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_50-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_51-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span>We respect the intellectual property rights (</span>
        <span className="c4">
          <strong>“IPR”</strong>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            ) of others, and we ask you to do the same. We may, in appropriate
            circumstances and at our discretion, terminate service and/or access
            to this Website and/or Application for users who infringe the IPR of
            others. Access to this Platform and/or Application does not confer
            and shall not be considered as conferring upon anyone any license
            under any of our or any third party’s intellectual property rights.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_52-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You grant the Company a non-exclusive, worldwide, perpetual,
            irrevocable, royalty free, sub-licensable (through multiple tiers)
            right to exercise the intellectual property in user Content.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_53-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            The content and Software on this Website and/or Application may be
            used only as a symptom checker resource. Any other use, including
            the reproduction, modification, distribution, transmission,
            republication, display, or performance, of the content on this
            Website and/or Application is strictly prohibited.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_54-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You recognize that the Company is the owner of all copyrights,
            trademarks, domains, logos, trade dress, trade secrets, patents and
            other intellectual property rights associated with our Products and
            Services. You shall not directly or indirectly, attack or assist
            another in attacking the validity of, or Company’s or its Affiliates
            proprietary rights in, the licensed marks or any registrations
            thereof, or file any applications for the registration of the
            licensed marks or any names or logos derived from or confusingly
            similar to the licensed marks, any variation thereof, or any
            translation or transliteration thereof in another language, in
            respect of any Products/Services and in any territory throughout the
            world. If you become aware or acquire knowledge of any infringement
            of IPR, you shall report the same at dectrocelhealthcare@gmail.com
            with all relevant information.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ol
    className="c3 lst-kix_list_55-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_55-0 9",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={10}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_55-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>PRIVACY AND CONFIDENTIALITY</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_56-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            Your use of the Platform is governed by our Privacy Policy. Please
            read our Privacy Policy to understand our information collection and
            usage practices.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_57-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span>
          You may obtain certain confidential information, including without
          limitation, technical, contractual, product, business related
          functions, activities and services, customer lists, knowledge of
          customer needs and preferences, business strategies, marketing
          strategies, methods of operation, markets and other valuable
          information that should reasonably be understood as confidential (
        </span>
        <span className="c4">
          <strong>“Confidential Information”</strong>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            ). You acknowledge and agree to hold all Confidential Information in
            strict confidence. Title and all interests to all Confidential
            Information shall be vested in us.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_58-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            Upon such termination, you must stop forthwith using any
            Confidential Information to which you may have been exposed in due
            course of your use of the Website. Company shall not be held liable
            for breach of confidentiality by any other User or person.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_59-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            The restrictions in this Clause shall not apply to disclosure of
            Confidential Information by either party if and to the extent the
            disclosure is:
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_60-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            required by the applicable law of any jurisdiction;
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            required by any applicable supervisory or regulatory or governmental
            body to which the relevant party is subject or submits, wherever
            situated, whether or not the requirement for disclosure has the
            force of law.
          </span>
        </span>
      </p>
    </li>
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            made to employees and representatives on a need-to-know basis,
            provided that such persons are required to treat such information as
            confidential through written agreement in terms which are no less
            strict than this Clause.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ol
    className="c3 lst-kix_list_61-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_61-0 10",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={11}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_61-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>HEALTH RELATED INFROMATION</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_62-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            We intend to provide users with general information about health and
            wellness through our Platform. This Website and/or Application and
            any information (including information provided on the Website by
            nutrition or healthcare professionals employed by or contracting
            with us) is not intended to provide diagnosis, treatment or medical
            advice. You should always consult with your healthcare professional
            prior to using any medication, nutritional or herbal or before
            beginning any diet program or starting any treatment for a health
            issue. Individuals are different and may react differently to
            different treatments. If you rely on any such information by the
            Company, you do so at your own risk.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>
        “Disclaimer: PLEASE NOTE THAT THE “DecXpert” PLATFORM AND OR APPLICATION
        DOES NOT MAKE ANY MEDICAL DIAGNOSES. PLEASE SEEK THE ADVICE OF A MEDICAL
        PROFESSIONAL IF YOU ARE CONCERNED ABOUT YOUR HEALTH. IN CASE OF AN
        EMERGENCY, YOU SHOULD CONTACT THE EMERGENCY SERVICES IMMEDIATELY. ALWAYS
        CONSULT YOUR DOCTOR OR ANOTHER QUALIFIED HEALTH CARE PROVIDER IF YOU
        HAVE ANY QUESTIONS REGARDING ANY MEDICAL ISSUES. YOU SHOULD NEVER
        DISREGARD A DOCTOR’S PROFESSIONAL ADVICE OR CANCEL AN APPOINTMENT WITH A
        DOCTOR BECAUSE YOU ARE RELYING ON INFORMATION YOU HAVE READ OR HEARD IN
        THE DecXpert PLATFORM.”
      </strong>
    </span>
  </p>
  <ul
    className="c3 lst-kix_list_63-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            The Company does not assume any responsibility for any injury or
            damage to any person arising out of or related to any use of
            recommendations given on our Platform.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ol
    className="c3 lst-kix_list_64-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_64-0 11",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={12}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_64-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>FORCE MAJEURE</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_65-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            We shall not be liable for any loss suffered by you due to failure
            and/or delay on our part in performing any of our obligations under
            the T&amp;C if such failure and/or delay was due to a Force Majeure
            Event.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_66-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            “Force Majeure Event” means any event due to any cause beyond the
            reasonable control of Company, including, without limitation,
            unavailability of any communication system, sabotage, fire, flood,
            earthquake ,explosion, epidemic, pandemic, acts of God, civil
            commotion, strikes, lockout, and/or industrial action of any kind,
            breakdown of transportation facilities, riots, insurrection,
            hostilities whether war be declared or not, acts of government,
            governmental orders or restrictions breakdown and/or hacking of the
            Website and/or Application and/or contents provided for availing the
            Services under the Website and/or Application, such that it is
            impossible to perform the obligations under the Agreement, or any
            other cause or circumstances beyond the control of Company which
            prevents timely fulfilment of obligation of the Company.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ol
    className="c3 lst-kix_list_67-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_67-0 11",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={12}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_67-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>1 TERMINATION</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_68-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            These T&amp;C are effective unless and until terminated by either
            you or us.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_69-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You may terminate the T&amp;C at any time by closing of your account
            on the Website and/or the Application.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_70-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            We may terminate these T&amp;C at any time and may do so immediately
            without notice, and accordingly deny you access to the Website
            and/or Application. Such termination will be without any liability
            to the Company.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_71-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            The rights and obligations of the Parties under these T&amp;C, which
            either expressly or by their nature survive the termination of these
            T&amp;C including but not limited to Clause 7 (Disclaimers and
            Limitation of Liability), Clause 8 (Indemnity), Clause 9
            (Intellectual Property Rights), Clause 10 (Privacy and
            Confidentiality), Clause 12 (Termination), and Clause 13 (Governing
            Law and Dispute Resolution) shall survive the termination of these
            T&amp;C.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_72-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            Except as otherwise specifically provided herein, the termination of
            these T&amp;C for any reason whatsoever shall be without prejudice
            to any rights or obligations accrued to or in respect of the parties
            prior to the date of termination.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_73-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            Upon termination, you shall immediately destroy any copies made of
            any portion of the content contained on the Website and/or
            Application other than usser Content. You agree that Company shall
            not be liable to you or any third party claiming through you, for
            any suspension or termination of access to Website and/or
            Application.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ol
    className="c3 lst-kix_list_74-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_74-0 12",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={13}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_74-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>GOVERNING LAW AND JURISDICTION</strong>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_75-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            The T&amp;C and the Policies shall be governed and construed in
            accordance with the laws of India.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_76-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span>
          Any dispute or difference arising relating to these T&amp;C or
          Policies (
        </span>
        <span className="c4">
          <strong>“Dispute”</strong>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            ) shall be referred to be resolved by arbitration in accordance with
            the Arbitration and Conciliation Act, 1996.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_77-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            When any Dispute is under arbitration, except for the matters under
            dispute, Company and you shall continue to exercise the remaining
            respective rights and fulfil the remaining respective obligations
            under these T&amp;C.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_78-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            The arbitration shall be conducted by a sole arbitrator jointly
            appointed by Company and you.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_79-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            The venue of arbitration shall be Lucknow, India and the language of
            the arbitration proceedings and of all written decisions and
            correspondence relating to the arbitration shall be English.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_80-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            If the Dispute has not been resolved by the arbitration, such a
            dispute shall be subject to the exclusive jurisdiction of the courts
            in Lucknow and you hereby submit to the jurisdiction of such courts.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_81-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You agree and acknowledge that the provisions relating to
            intellectual property (Clause 9) and privacy and confidentiality
            (Clause 10) are of importance to Company and monetary compensation
            may not constitute adequate relief and remedy to Company for
            non-performance by you of your obligations thereunder. Accordingly,
            Company shall be entitled to claim specific performance and seek
            mandatory and/or perpetual injunctions requiring you to perform your
            duties and obligations under such provisions.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ol
    className="c3 lst-kix_list_82-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_82-0 13",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
    start={14}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_82-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>GENERAL PROVISIONS</strong>
        </span>
      </p>
    </li>
  </ol>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span className="c4">
      <strong>&nbsp;</strong>
    </span>
  </p>
  <ul
    className="c3 lst-kix_list_83-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>Communications:</strong>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            &nbsp;You hereby expressly agree to receive communication or by way
            of SMS and/or E-mail or through WhatsApp from the Company or any
            third party in connection with the Services or your registration on
            the Website and/or Application. We may contact you telephonically or
            through emails to introduce new Product/Service offerings and in
            case of you do not want us to contact you, you are requested to
            actively opt out.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_84-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>Notice:</strong>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            &nbsp;All notices from the Company will be served by email to your
            registered email address or by general notification on the website.
            You consent to the use of electronic communications in order to the
            electronic delivery of notices in relation to any matter under these
            T&amp;C. Any notice provided to the Company pursuant to the T&amp;C
            should be sent to dectrocelhealthcare@gmail.com with subject line
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_85-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>Attention:</strong>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            &nbsp;T&amp;C. Notices sent to the Company shall be effective 3
            (three) business days after they are sent.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_86-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>Assignment:</strong>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            &nbsp;You cannot assign or otherwise transfer the T&amp;C, or any
            rights granted hereunder or any obligations, to any third party and
            any such assignment or transfer or purported assignment or transfer
            shall be void ab initio. The Company’s rights and/or obligations
            under the Agreement are freely assignable or otherwise transferable
            by the Company to any third parties without the requirement of
            seeking Your prior consent. The Company shall be entitled to assign
            their rights and obligations hereunder to any affiliate, third party
            or as part of any restructuring, business combination, merger or
            acquisition.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_87-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>Severability:</strong>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            &nbsp;If, for any reason, a court of competent jurisdiction finds
            any provision of the T&amp;C, or any portion thereof, to be
            unenforceable, that provision shall be enforced to the maximum
            extent permissible so as to give effect to the intent of the parties
            as reflected by that provision, and the remainder of the T&amp;C
            shall continue in full force and effect.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ul
    className="c3 lst-kix_list_88-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>Waiver:</strong>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            &nbsp;Any failure by the Company to enforce or exercise any
            provision of the T&amp;C, or any related right, shall not constitute
            a waiver by the Company of that provision or right.
          </span>
        </span>
      </p>
    </li>
  </ul>
  <ol
    className="c3 lst-kix_list_89-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_89-0 0",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_89-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>CONTACT</strong>
        </span>
      </p>
    </li>
  </ol>
  <ol
    className="c3 lst-kix_list_90-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_90-0 0",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_90-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span>
          For any inquiries regarding the collection, processing, usage, or
          disclosure of your information, please email us at&nbsp;
        </span>
        <span style={{ color: "rgb(5,99,193)" }}>
          <span className="c8" style={{ textDecorationSkipInk: "none" }}>
            <u>dectrocelhealthcare@gmail.com</u>
          </span>
        </span>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            .
          </span>
        </span>
      </p>
    </li>
  </ol>
  <ol
    className="c3 lst-kix_list_91-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_91-0 0",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_91-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            You may also withdraw your consent for us to use and process your
            information by sending a written request to the aforementioned email
            id.
          </span>
        </span>
      </p>
    </li>
  </ol>
  <ol
    className="c3 lst-kix_list_92-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      counterReset: "lst-ctn-kix_list_92-0 0",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        counterIncrement: "lst-ctn-kix_list_92-0 1",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(0,0,0)",
            fontFamily: "Calibri",
            fontSize: "14pt"
          }}
        >
          <span
            className="c0"
            style={{
              fontStyle: "normal",
              fontWeight: 400,
              textDecoration: "none",
              verticalAlign: "baseline"
            }}
          >
            We have a no refund policy.
          </span>
        </span>
      </p>
    </li>
  </ol>
  <ul
    className="c3 lst-kix_list_93-0 start"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Times",
      fontSize: "medium",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      orphans: 2,
      padding: 0,
      textAlign: "start",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <li
      className="c1 c2 li-bullet-0"
      style={{
        color: "rgb(0, 0, 0)",
        fontFamily: "Calibri",
        fontSize: "14pt",
        lineHeight: "1.07917",
        orphans: 2,
        paddingBottom: "8pt",
        paddingLeft: "0pt",
        paddingTop: "0pt",
        textAlign: "left",
        widows: 2
      }}
    >
      <p style={{ marginLeft: 0 }}>
        <span className="c4">
          <strong>Contact Information:</strong>
        </span>
      </p>
    </li>
  </ul>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        Customer Support
      </span>
    </span>
  </p>
  <p
    className="c1"
    style={{
      WebkitTextStrokeWidth: 0,
      color: "rgb(0, 0, 0)",
      fontFamily: "Calibri",
      fontSize: "14pt",
      fontStyle: "normal",
      fontVariantCaps: "normal",
      fontVariantLigatures: "normal",
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "1.07917",
      margin: 0,
      orphans: 2,
      paddingBottom: "8pt",
      paddingTop: "0pt",
      textAlign: "left",
      textDecorationColor: "initial",
      textDecorationStyle: "initial",
      textDecorationThickness: "initial",
      textIndent: 0,
      textTransform: "none",
      whiteSpace: "normal",
      widows: 2,
      wordSpacing: 0
    }}
  >
    <span
      style={{ color: "rgb(0,0,0)", fontFamily: "Calibri", fontSize: "14pt" }}
    >
      <span
        className="c0"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          textDecoration: "none",
          verticalAlign: "baseline"
        }}
      >
        E-mail id:&nbsp;dectrocelhealthcare@gmail.com
      </span>
    </span>
  </p>
  <p>
    <br />
    &nbsp;
  </p>
</>






            </div>
        </div>
        </div>
            </div>
        
        </div>
      )}
    </>
    )

}

export default TermsConditions;