import React, { useEffect, useState } from 'react';
import { ApiService } from '../../services/ApiService';
import { useNavigate } from 'react-router-dom';

const AllReportPage = () => {
    const navigate = useNavigate();
  const [reports, setReports] = useState([]);

  useEffect(() => {
    getallReports();
  }, []);

  const getallReports = async () => {
    let payloadUrl = `user/getAllUserReport`;
    let method = "GET";
    const res = await ApiService.fetchData(payloadUrl, method);
    console.log(res);
    let resultArr = res.results;
    setReports(resultArr);
  }

  const downloadreport = (reportID) =>{
    navigate(`/dashboard/report?rid=${reportID}`);
  }

  return (
    <div className="container mt-4">
     <center><h1 style={{marginRight:"147px"}}>Reports</h1></center> 
      <hr></hr>
      <table className="table table-sm table-bordered">
        <thead class="thead-dark">
          <tr>
            <th>Report No.</th>
            <th>Report Date</th>
            <th>Type</th>
            <th>Fullname</th>
            <th>Mobile</th>
            <th>Age & Gender</th>
            <th>View Report</th>
          </tr>
        </thead>
        <tbody>
          {reports.map(report => (
            <tr key={report.result_id}>
                <td><b>#{report.result_id}</b></td>
                <td>{report.test_date}</td>
                <td>{(report.test_type_id == 1)?'Digital Pathology':(report.test_type_id == 2)?'TB Scan':(report.test_type_id == 3)?"Digital Pathology + TB Scan":''}</td>
                <td>{report.p_name}</td>
                <td>{report.p_mobile}</td>
                <td>{report.p_age} <span className='bg-warning' style={{padding:"3px",borderRadius:"10px"}}>{report.p_gender}</span></td>
                <td>
                <a onClick={()=>{downloadreport(report.result_id)}} style={{cursor:"pointer"}}>
                  View Report
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllReportPage;
