import React from 'react';
import logo from '../../assets/img/user.png';
import './css/preloader.css';


const Preloader = () => {
  return (
    <div className="pre_app">
      <div className="background-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/sgpgi.jpeg)`}}></div>
      <div className="logo-container">
      <img src={process.env.PUBLIC_URL + '/assets/img/sgpgi_logo22.png'} alt="Logo" className="logo-sgpgi" />
      </div>
    </div>
  );
};

export default Preloader;
