import { createContext, useContext, useState } from 'react';

const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [myState, setMyState] = useState('Initial Value');
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [testTypeID, setTestTypeID] = useState(0);
  const [creditID, setCreditID] = useState(0);
  const [subsID, setSubsID] = useState(0);

  const updateState = (newValue) => {
    setMyState(newValue);
  };

  return (
    <MyContext.Provider value={{ myState, updateState,userAuthenticated, setUserAuthenticated,userProfile, setUserProfile,testTypeID, setTestTypeID,creditID, setCreditID,subsID, setSubsID }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => {
  return useContext(MyContext);
};
