import React, { useEffect,useState } from 'react';
import { Container, Form, Button,FormCheck,Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { ApiService, setAuthorization } from '../../services/ApiService';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';
import { SetCookie,SetUserLogin,RedirectToLogin } from '../../components/helpers/helper';
import './css/login.css';
import { useMyContext } from '../../MyContext';



const Register = () => {
  const navigate = useNavigate();
  const { userAuthenticated,setUserAuthenticated } = useMyContext();
  const { register, handleSubmit, watch, setValue, reset, resetField, trigger, clearErrors, setError, formState: { errors } } = useForm();
  const [otpSent, setOtpSent] = useState(false);
  const [formSubmitted, setFormSbmt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const toggleAlert = (val) => {
    setShowAlert(val)
}

useEffect(() => {
  if(localStorage.getItem('currentUserSession')){
    navigate('/dashboard')
  }
  else{
    //RedirectToLogin();
  }
},[])

  const onSubmit = async (data = {}) => {
    if(data == null){
      return false
  }
  setLoading(true);
  setFormSbmt(true)
  let payloadUrl = "auth/sendOTP"
  let method = "POST"
  let formData = {mobilenumber:parseInt(data.mobile, 10),type:'register'}
  const res = await ApiService.fetchData(payloadUrl,method,formData)
  console.log(res);
  if( res && res.status_code){
      let userData = res.results
      if(res.status_code == "dc200"){
        setOtpSent(true);
      }else{
        toggleAlert({ show: true, type: 'danger', message: res.message });
      }
      
  }
  setLoading(false);
  setFormSbmt(false)
  }



  const onVerifyOTP = async (otp,mobile,fullname,password,account_type) => {
    if(otp == null){
      return false
  }
  setLoading(true);
  setFormSbmt(true)
  let payloadUrl = "auth/register"
  let method = "POST"
  let formData = {fullname:fullname,mobile:mobile,password:password,account_type:parseInt(account_type),otp:parseInt(otp)}
  const res = await ApiService.fetchData(payloadUrl,method,formData)
  console.log(res);
  if( res && res.status_code){
      let result = res.results
      if(res.status_code == "dc200"){
        setLoading(false);
        let auth = SetUserLogin(result);
        if(auth){
        setUserAuthenticated(true);
        navigate('/dashboard');
        }
        //toggleAlert({ show: true, type: 'success', message: res.message });
      }else{
        setLoading(false);
        toggleAlert({ show: true, type: 'danger', message: res.message });
      }
      
  
  }
 
  };

  return (
    <>
    <div className="login-dark" style={{ minHeight: '100vh', background: '#214a80', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize:"16px" }}>
      <Container>
        <form style={{ maxWidth: '400px' }} onSubmit={handleSubmit(onSubmit)}>
        <img src="/assets/img/sgpgi_logo22.png" style={{ height: '80px',backgroundColor:"white" }}/>
        <img src="assets/img/dexpert_white01.png" className="logo" alt="Logo" style={{ height: '40px' }} /><span style={{position:"absolute",marginTop:"10px"}}>&#174;</span>
        <div className='text-type mt-20'>Create New Account</div>
          <div className="illustration"><i className="icon ion-ios-locked-outline"></i></div>
          <Form.Group>
            <Form.Control style={{fontSize:"18px"}} as="select" {...register("account_type", { required: true })} className="form-control">
              <option value="1">Individual</option>
              <option value="2">Doctor</option>
              <option value="3">Hospital</option>
              <option value="4">Diagnostic Centre</option>
              {/* Add more options as needed */}
            </Form.Control>
            {errors.accountType?.type === "required" && <small className="form-text text-danger">Account type is required</small>}
          </Form.Group>
          <Form.Group>
            <Form.Control style={{fontSize:"18px"}} type="text" {...register("fullname", { required: true })} placeholder="Enter Fullname" className="form-control" />
            {errors.fullname?.type === "required" && <small className="form-text text-danger">fullname is required</small>}
          </Form.Group>
          <Form.Group>
            <Form.Control style={{fontSize:"18px"}} type="number" {...register("mobile", { required: "mobile number is required",maxLength: {value: 10,message: "enter only 10 digit mobile number" },minLength: {value: 10,message: "Enter at least 10 digits for the mobile number"} })} placeholder="Mobile Number"
             className="form-control" disabled={otpSent} />
            {errors.mobile && <small className="form-text text-danger">{errors.mobile.message}</small>}
          </Form.Group>
          <Form.Group>
            <Form.Control style={{fontSize:"18px"}} type="password" {...register("password", { required: "password is required",minLength: {value: 6,message: "minimum length should be 6 characters" } })} placeholder="Password" className="form-control" />
            {errors.password && <small className="form-text text-danger">{errors.password.message}</small>}
          </Form.Group>
          <Form.Group>

          {otpSent ? (
            <div>
              <Form.Group>
                <Form.Control style={{fontSize:"18px"}} type="text" {...register('otp', { required: 'OTP is required',minLength: {value: 4,message: "enter 4 digit OTP" } })} placeholder="Enter OTP" className="form-control" />
                {errors.otp && <small className="form-text text-danger">{errors.otp.message}</small>}
              </Form.Group>
              <Button style={{fontSize:"18px"}} className="btn btn-primary btn-block" type="button" onClick={() => onVerifyOTP(watch('otp'),watch('mobile'), watch('fullname'), watch('password'), watch('account_type'))}>
              {loading ? (
              <Spinner animation="border" role="status" variant="light" size='sm'>
              </Spinner>
            ) : (
              <></>
            )}Verify OTP & Register
              </Button>
            </div>
          ) : (
            <Button style={{fontSize:"18px"}} className="btn btn-primary btn-block" type="submit" disabled={loading}>
              {loading ? (
              <Spinner animation="border" role="status" variant="light" size='sm'>
              </Spinner>
            ) : (
              <></>
            )}Register
            </Button>
          )}
          </Form.Group>
          <small style={{fontSize:"18px"}}>Already Registered Please </small><a href="/login" className='text-primary'>Login here</a>
          <br/><br/>
          <a className="text-primary text-center" href="/terms" style={{fontSize:"15px"}}>
          By completing this form, you agree to the Terms and Conditions
          </a>
        </form>
      </Container>
    </div>
    {(() => {
                if (showAlert && showAlert.show && showAlert.type == "danger") {
                    return (
                        <SweetAlert
                            danger
                            title={showAlert.message}
                            onConfirm={() => toggleAlert({ show: false, type: 'success', message: '' })}
                            confirmBtnCssClass={'btn btn-primary'}
                            onCancel={() => toggleAlert({ show: false, type: 'success', message: '' })}
                            showConfirm={true}
                            focusCancelBtn={false}
                            customClass={'i_alert fs-10'}
                            timeout={3000}
                        />
                    )
                }
            })()}
    </>
  );
};

export default Register;
