import React,{useState,useEffect} from 'react';
import HelperModal from '../helpers/modal';
import { useNavigate,useLocation } from 'react-router-dom';
import { ApiService, setAuthorization } from '../../services/ApiService';
import { useMyContext } from '../../MyContext';

const Content = () => {
  const { testTypeID, setTestTypeID,creditID, setCreditID,subsID, setSubsID } = useMyContext();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [openModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null)
  const [modalData, setModalData] = useState({});
  const [payplanID, setPayplanID] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [hasCredit, setHasCredit] = useState(0);
  const [creditPlan, setCreditPlan] = useState({});
  const [subsPlan1, setSubsPlan1] = useState({});
  const [subsPlan2, setSubsPlan2] = useState({});
  const [currentTestID, setCurrentTestID] = useState();
  const planID = queryParams.get('planID');

  const hideModal = () => {
    setModalType(null);
    setShowModal(false);
};
const showModal = async (modalName = null, data = null) => {
  if (modalName == null) {
    return false;
  }
  setModalData({})
  switch (modalName) {
    case "payment_link":
    if (data != null) {
        setPayplanID(data)
        setModalType(modalName);
        setShowModal(true);
      }
    break;
  }
};

//use effect
useEffect(() => {
  verifyUserSubscription();
  if (planID) {
   // console.log('planID is available:', planID);
    showModal('payment_link', planID);
  } 
}, []);

const verifyUserSubscription = async () => {
let payloadUrl = "user/getUserSubscriptions"
let method = "GET"
let formData = {}
const res = await ApiService.fetchData(payloadUrl,method,formData)
console.log(res);
if( res && res.status_code){
    let result = res.data.result;
    if(result == "yes"){
      console.log(res.data);
      let arraylength = res.data.details.length;
      setSubsID(res.data.details[0].subscription_id[0]);
      if(arraylength == 2){
        setCurrentTestID(2);
        let data = {};
        data.planName = res.data.details[0].plan_name;
        data.end_date = res.data.details[0].end_date;
        data.credits = res.data.details[0].sum_available_credit;
        setSubsPlan1(data);

        let data2 = {};
        data2.planName = res.data.details[1].plan_name;
        data2.end_date = res.data.details[1].end_date;
        data2.credits = res.data.details[1].sum_available_credit;
        setSubsPlan2(data2);

      } else if(arraylength == 1){
        setCurrentTestID(1);
        let data = {};
        data.planName = res.data.details[0].plan_name;
        data.end_date = res.data.details[0].end_date;
        data.credits = res.data.details[0].sum_available_credit;
        setSubsPlan1(data);
      }
      setIsSubscribed(true)
      
    }else if(result == "no"){
      setIsSubscribed(false)
    }
    else if(result == "credit"){
      setHasCredit(res.data.credit);
      setCreditID(res.data.credit_id);
      let data ={};
      data.planName = res.data.planName;
      data.planID = res.data.planID;
      setCreditPlan(data);
    }
}

};

const RedirectToScanPage = async(testID)=>{
  // testTypeID 1- Digital Pathology 2- TB Scan 3-Combine Scan 
if(testID == 1 || testID == 2 || testID == 3){
  setTestTypeID(testID);
  navigate('/dashboard/scan');
}
else{
  setTestTypeID(0);
}


}

  return (
    <>
    
    <div className="container-fluid">
      {/* Page Heading */}
      <div className=" mb-4">
        <center><h1 className="h3 mb-0 text-gray-800" style={{marginRight:"147px"}}>Welcome</h1></center>
      </div>

      {/* Content Row */}
      <div className="row">
        {/* Earnings (Monthly) Card Example */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                   Current Plan
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {(!isSubscribed)?"No Active Plan":(subsPlan2.planName)?`${subsPlan1.planName} , ${subsPlan2.planName}` : subsPlan1.planName }
                  </div>
                 
                </div>
                <div className="col-auto">
                  <i className="fas fa-calendar fa-2x text-gray-300"></i>
                </div>
              </div>
              <div className='row'>
             
              </div>
            </div>
          </div>
        </div>

        {/* Earnings (Annual) Card Example */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Scan Credit
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                  {(!isSubscribed && hasCredit == 0)?"0":(!isSubscribed && hasCredit !=0)?hasCredit:(subsPlan2.planName)?`${subsPlan1.credits} , ${subsPlan2.credits}` : subsPlan1.credits }
                  </div>
                  <small className="text-dark">
                
                  </small>
                </div>
                <div className="col-auto">
                  <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tasks Card Example */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Scan Credit For
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                  {(!isSubscribed && hasCredit == 0)?"-":(!isSubscribed && hasCredit !=0)?creditPlan.planName:(subsPlan2.planName)?`Digital Pathology , TB Scans` : 'Digital Pathology+TB' }
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pending Requests Card Example */}
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                    Plan Expires on
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                  {(!isSubscribed)?"-":(subsPlan2.planName)?`${subsPlan1.end_date} , ${subsPlan2.end_date}` : subsPlan1.end_date }
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-comments fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(isSubscribed || hasCredit != 0)?( 
      <div className="row">
        <div class="col-xl-2 col-md-2 col-lg-2"></div>
        <div class="col-xl-8 col-md-6 col-lg-6">
          <div class="card border-bottom-primary">
            <div class="card-body text-center">
              <div class="counter-icon bg-primary-gradient">
                <i class="fas fa-clipboard-list fa-2x" style={{color:"chocolate"}}></i>
              </div>
              <p>
              Experience the cutting-edge in healthcare with DecXpert, harnessing the latest Artificial Intelligence technologies. 
              Please select from the below Test Options.
              </p>
              <div className="row justify-content-center">
          <div className="col-md-6 pl-75" style={{ textAlign: "left" }}>
            <p className="mb-2"> <b>Follow these simple steps:</b></p>
            <ol className="mb-4">
              <li> <i class="fas fa-arrow-circle-right text-warning"></i> Upload a good quality X-ray image.</li>
              <li> <i class="fas fa-arrow-circle-right text-warning"></i> Click on the "Analyse" button.</li>
              <li> <i class="fas fa-arrow-circle-right text-warning"></i> Wait for the result and download.</li>
            </ol>
          </div>
        </div>
              {
                (creditPlan.planID == '9')?<><button class="btn btn-colour-1 label-btn" onClick={()=>RedirectToScanPage(1)}> <i class="fas fa-arrow-circle-up"></i> Proceed to Digital Pathology Scan </button></>:
                (creditPlan.planID == '10')?<><button class="btn btn-colour-2 label-btn ml-10" onClick={()=>RedirectToScanPage(2)}> <i class="fas fa-arrow-up"></i> Proceed to TB Scan </button></>:<></>
              }
              {
                (isSubscribed && currentTestID)?(currentTestID == 1)?<>
                {/* show combined scan button */}
                <button class="btn btn-colour-1 label-btn" onClick={()=>RedirectToScanPage(3)}> <i class="fas fa-arrow-circle-up"></i> Proceed to Digital Pathology + TB Scan </button>
                </>:(currentTestID == 2)?<>
                 {/* show combined scan button + all buttons  */}
                 <button class="btn btn-colour-1 label-btn mb-10" onClick={()=>RedirectToScanPage(3)}> <i class="fas fa-arrow-circle-up"></i> Proceed to Digital Pathology + TB Scan </button>
                 <button class="btn btn-colour-2 label-btn ml-10 mb-10" onClick={()=>RedirectToScanPage(1)}> <i class="fas fa-arrow-up"></i> Proceed to Digital Pathology Scan Only </button>
                 <button class="btn btn-colour-2 label-btn ml-10" onClick={()=>RedirectToScanPage(2)}> <i class="fas fa-arrow-up"></i> Proceed to TB Scan Only </button>
                </>:<></>:<></>
              }
              
              
            </div>
          </div>
        </div>
      </div>)
      :
       <div className="row">
       <div class="col-xl-2 col-md-2 col-lg-2"></div>
       <div class="col-xl-8 col-md-6 col-lg-6">
         <div class="card border-bottom-primary">
           <div class="card-body text-center">
             <div class="counter-icon bg-primary-gradient">
               <i class="fas fa-clipboard-list fa-2x" style={{color:"chocolate"}}></i>
             </div>
             <p>
             Experience the cutting-edge in healthcare with DecXpert, harnessing the latest Artificial Intelligence technologies. 
             </p>
             <p><b className="text-danger">You have zero Credit ! Please buy subscription plan or Do a Quick Scan below</b></p>
             <button class="btn btn-colour-3 label-btn" onClick={(e) => showModal('payment_link', '9', e)}> <i class="fas fa-arrow-circle-up"></i> Digital Pathology + TB Scan ₹100</button>
             <button class="btn btn-colour-4 label-btn ml-10" onClick={(e) => showModal('payment_link', '10', e)}> <i class="fas fa-arrow-up"></i> Digital Pathology OR TB Scan ₹50</button>
           </div>
         </div>
       </div>
     </div>
      }
     
    </div>
    {(() => {
              if (modalType && modalType != "" && modalType != null) {
                if (modalType == "payment_link") {
                  return (
                    <HelperModal
                      show={openModal}
                      modalType={modalType}
                      hideModal={hideModal}
                      modalData={{payplanID:payplanID}}
                      formSubmit={()=>{}}
                    />
                  );
                }
              }
              
          })()}
    </>
  );
}

export default Content;
