import React from 'react';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const PricingSection = () => {
  const navigate = useNavigate();

  const redirectToDashboard = (planID) =>{
    if(localStorage.getItem('currentUserSession')){
      navigate(`/dashboard?planID=${planID}`);
    }
    else{
      navigate('/login')
    }
  }
  return (
    <div id="pricing" className="pricing-style-three-area overflow-hidden default-padding bg-gray bottom-less">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading secondary text-center">
              <h4 className="sub-heading">Best Pricing</h4>
              <h2 className="heading">Best plan that best suits your business requirements</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
      <div className="row">
  <div className="col-xl-10 offset-xl-1 text-center pb-5">
    <div className="nav nav-tabs service-overview-tab-navs" id="nav-tab" role="tablist">
      <button className="nav-link active" id="nav-id-11" data-bs-toggle="tab" data-bs-target="#tab11" type="button" role="tab" aria-controls="tab11" aria-selected="true">
        Digital Pathology + TB Scans
      </button>
      <button className="nav-link" id="nav-id-12" data-bs-toggle="tab" data-bs-target="#tab12" type="button" role="tab" aria-controls="tab12" aria-selected="false">
        only Digital Pathology
      </button>
      <button className="nav-link" id="nav-id-12" data-bs-toggle="tab" data-bs-target="#tab12" type="button" role="tab" aria-controls="tab12" aria-selected="false">
        only TB Scan
      </button>
    </div>
    
  </div>

  <div className="col-xl-12 col-lg-12">
    <div className="tab-content" id="nav-tabContent">
      <div className="tab-pane fade show active" id="tab11" role="tabpanel" aria-labelledby="nav-id-11">
        
        <div className="row">
        

          <div className="col-lg-3 col-md-6 pricing-style-one">
            {/* Content for Standard Plan */}
            <div className="item">
              <div className="pricing-header">
                <h4>Basic Plan</h4>
                <p>Affordable Entry-Level Scanning</p>
              </div>
              <div className="pricing-content">
                <div className="price">
                  <h2><sup>₹</sup>22 <sub>/ Scan</sub></h2>
                </div>
                <ul>
                  <li><i className="fas fa-check-circle"></i> 300 Scans</li>
                  <li><i className="fas fa-check-circle"></i>  ₹6600 per Month</li>
                </ul>
                <a onClick={()=>redirectToDashboard(1)} className="btn circle mt-25 btn-sm btn-border effect" >Purchase Plan</a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 pricing-style-one">
            {/* Content for Premium Plan */}
            <div className="item">
              <div className="pricing-header">
                <h4>Standard Plan</h4>
                <p>Efficient Scan Processing with Client Services</p>
              </div>
              <div className="pricing-content">
                <div className="price">
                  <h2><sup>₹</sup>20 <sub>/ Scan</sub></h2>
                </div>
                <ul>
                  <li><i className="fas fa-check-circle"></i> 900 Scans</li>
                  <li><i className="fas fa-check-circle"></i> ₹18000 per Month</li>
                  <li><i className="fas fa-check-circle"></i> Client Services Included</li>
                </ul>
                <a onClick={()=>redirectToDashboard(2)} className="btn circle mt-25 btn-sm btn-border effect" >Purchase Plan</a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 pricing-style-one active">
            {/* Content for Business Plan */}
            <div className="item">
              <div className="pricing-header">
                <h4>Premium Plan</h4>
                <p>Advanced Processing Power with Affodable cost</p>
              </div>
              <div className="pricing-content">
                <div className="price">
                  <h2><sup>₹</sup>15 <sub>/ Scan</sub></h2>
                </div>
                <ul>
                  <li><i className="fas fa-check-circle"></i> 3000 Scans</li>
                  <li><i className="fas fa-check-circle"></i> ₹45000 per Month</li>
                  <li><i className="fas fa-check-circle"></i> Client Services Included</li>
                </ul>
                <a onClick={()=>redirectToDashboard(3)} className="btn circle mt-25 btn-sm btn-theme animation" >Purchase Plan</a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 pricing-style-one">
            {/* Content for Basic Plan */}
            <div className="item">
              <div className="pricing-header">
                <h4>Tele-radiology</h4>
                <p>Rapid Deployment for High-Volume Scanning</p>
              </div>
              <div className="pricing-content">
                <div className="price">
                <h2 style={{fontSize:"35px"}}><sup>₹</sup>12 <sub>/ Scan</sub></h2>
                </div>
                <ul>
                 <li><i className="fas fa-check-circle"></i> 60,000 Scan</li>
                 <li><i className="fas fa-check-circle"></i> ₹7,20,000 per month</li>
                  <li><i className="fas fa-check-circle"></i> Client Services Included</li>
                </ul>
                <a onClick={()=>redirectToDashboard(4)} className="btn circle mt-25 btn-sm btn-border effect" >Purchase Plan</a>
              </div>
            </div>
          </div>

          <center>
          <h4 className="heading pt-5">Start now with Pay per scan <sup>₹</sup>100 </h4>
          <a onClick={()=>redirectToDashboard(9)} className="btn circle btn-sm btn-border effect" >Scan now</a>
          </center>
        </div>
      </div>

      <div className="tab-pane fade" id="tab12" role="tabpanel" aria-labelledby="nav-id-12">
      <div className="row">
        
        <div className="col-lg-3 col-md-6 pricing-style-one">
          {/* Content for Standard Plan */}
          <div className="item">
            <div className="pricing-header">
              <h4>Basic Plan</h4>
              <p>Affordable Entry-Level Scanning</p>
            </div>
            <div className="pricing-content">
              <div className="price">
                <h2><sup>₹</sup>20 <sub>/ Scan</sub></h2>
              </div>
              <ul>
                <li><i className="fas fa-check-circle"></i> 300 Scans</li>
                <li><i className="fas fa-check-circle"></i> ₹6000 per Month</li>
              </ul>
              <a onClick={()=>redirectToDashboard(5)} className="btn circle mt-25 btn-sm btn-border effect" >Purchase Plan</a>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 pricing-style-one">
          {/* Content for Premium Plan */}
          <div className="item">
            <div className="pricing-header">
              <h4>Standard Plan</h4>
              <p>Efficient Scan Processing with Client Services</p>
            </div>
            <div className="pricing-content">
              <div className="price">
                <h2><sup>₹</sup>18 <sub>/ Scan</sub></h2>
              </div>
              <ul>
                <li><i className="fas fa-check-circle"></i> 900 Scans</li>
                <li><i className="fas fa-check-circle"></i> ₹16200 per Month</li>
                <li><i className="fas fa-check-circle"></i> Client Services Included</li>
              </ul>
              <a onClick={()=>redirectToDashboard(6)} className="btn circle mt-25 btn-sm btn-border effect" >Purchase Plan</a>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 pricing-style-one active">
          {/* Content for Business Plan */}
          <div className="item">
            <div className="pricing-header">
              <h4>Premium Plan</h4>
              <p>Advanced Processing Power with Affodable cost</p>
            </div>
            <div className="pricing-content">
              <div className="price">
                <h2><sup>₹</sup>12 <sub>/ Scan</sub></h2>
              </div>
              <ul>
                <li><i className="fas fa-check-circle"></i> 3000 Scans</li>
                <li><i className="fas fa-check-circle"></i> ₹36000 per Month</li>
                <li><i className="fas fa-check-circle"></i> Client Services Included</li>
              </ul>
              <a onClick={()=>redirectToDashboard(7)} className="btn circle mt-25 btn-sm btn-theme animation" >Purchase Plan</a>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 pricing-style-one">
          {/* Content for Basic Plan */}
          <div className="item">
            <div className="pricing-header">
              <h4>Tele-radiology</h4>
              <p>Rapid Deployment for High-Volume Scanning</p>
            </div>
            <div className="pricing-content">
              <div className="price">
              <h2 style={{fontSize:"34px"}}><sup>₹</sup>10 <sub>/ Scan</sub></h2>
              </div>
              <ul>
               <li><i className="fas fa-check-circle"></i> 60,000 Scan</li>
               <li><i className="fas fa-check-circle"></i> ₹6,00,000 per Month</li>
                <li><i className="fas fa-check-circle"></i> Client Services Included</li>
              </ul>
              <a onClick={()=>redirectToDashboard(8)} className="btn circle mt-25 btn-sm btn-border effect" >Create Account</a>
            </div>
          </div>
        </div>
        <center>
        <h4 className="heading pt-5">Start now with Pay per scan <sup>₹</sup>50 </h4>
          <a onClick={()=>redirectToDashboard(11)} className="btn circle btn-sm btn-border effect" >Scan now</a>
          </center>
      </div>
      </div>
    </div>
  </div>
</div>

        
      </div>
      </div>
  );
};

export default PricingSection;
