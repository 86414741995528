import React from 'react';

const FAQSection = () => {
  return (
   
    <div className="faq-style-three-area bg-gray default-padding" style={{ backgroundImage: "url(assets/img/banner6.png)", backgroundSize:"100% 100%",backgroundRepeat:"no-repeat",backgroundPosition:"center center" }}>
      <a href="#" className="video-play-button-standard popup-youtube">
        <i className="fas fa-info"></i>
        <div className="effect"></div>
      </a>

      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="faq-style-three">
              <div className="heading">
                <h2 className="title">Want to ask <br />something from us?</h2>
              </div>

              <div className="accordion mt--20" id="faqAccordionThree">
                {/* Question 1 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                      Is DecXpert compatible with both analog and digital X-ray images?
                    </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#faqAccordionThree">
                    <div className="accordion-body">
                      <p>
                        Yes, DecXpert works seamlessly with both analog and digital X-ray images.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Question 2 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      How accurate is DecXpert in detecting abnormalities?
                    </button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#faqAccordionThree">
                    <div className="accordion-body">
                      <p>
                      DecXpert boasts an impressive 97-99% accuracy rate in detecting 18 abnormal radiologic findings.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Question 3 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      Can DecXpert detect early-stage lung cancer?
                    </button>
                  </h2>
                  <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#faqAccordionThree">
                    <div className="accordion-body">
                      <p>
                        Absolutely, DecXpert aids in the early detection of lung cancer, improving patient outcomes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
