import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Sidebar = ({ isSidebarToggled,toggleSidebar }) => {
  return (
    <>
    <ul
      className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${isSidebarToggled ? 'toggled' : ''}`}
      id="accordionSidebar"
    >
      {/* Sidebar - Brand */}
      <a className="sidebar-brand d-flex align-items-center justify-content-center">
        <div className="sidebar-brand-icon">
            <a onClick={toggleSidebar} href='#'>
            <i className="fas fa-bars"></i>
            </a>
          
        </div>
        <div className="sidebar-brand-text mx-3">DecXpert</div>
      </a>

      {/* Divider */}
      <hr className="sidebar-divider my-0" />

      {/* Nav Item - Dashboard */}
      <li className="nav-item active">
      <Link className="nav-link" to="/dashboard"> <i className="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span></Link>
      </li>
      <li className="nav-item active">
        <Link className="nav-link" to="/dashboard/plan">
          <i className="fas fa-fw fa-ticket"></i>
          <span>Subscription Plan</span>
       </Link>
      </li>
      <li className="nav-item active">
        <Link className="nav-link" to="/dashboard/allreports">
          <i className="fas fa-fw fa-file"></i>
          <span>Reports</span>
       </Link>
      </li>

      {/* Divider */}
      <hr className="sidebar-divider" />
      <img src="../assets/img/dectrocel.jpeg" className='img-responsive' style={{ height: '80px',bottom:"10px",position:"absolute" }} ></img>
    </ul>
   
    
   
    </>
  );
};

export default Sidebar;
